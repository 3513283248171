import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-pedidos',
	templateUrl: './pedidos.component.html',
	styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit, OnDestroy {
	pageActivos: any = {
		length: 0,
		pageIndex: 0,
		pageSize: 2,
		previousPageIndex: 0
	};
	pageTerminados: any = {
		length: 0,
		pageIndex: 0,
		pageSize: 2,
		previousPageIndex: 0
	};
	pageCancelado: any = {
		length: 0,
		pageIndex: 0,
		pageSize: 2,
		previousPageIndex: 0
	};
	idUsuario: any = {};
	idIntelisis: any = {};
	loading: boolean = false;
	pedidosActivos: any[] = [];
	pedidosTerminados: any[] = [];
	pedidosCancelados: any[] = [];
	urlPdfOpenPay = environment.urlPdfOpenPay;
	idOpenPay = environment.idOpenPay;
	urlPedido = environment.APIEndpoint;
	constructor(
		private servicios: ApiService,
		private sessionServices: SessionService,
		public dialog: MatDialog,
		private router: Router
	) { }

	ngOnInit() {
		this.subs.push(this.sessionServices.sessionObservable.subscribe(cliente => {
			this.idUsuario = cliente.id_cliente;
			this.idIntelisis = cliente.respuesta_intelisis.data.cliente;
			this.getPedidos();
			//this.openModalEliminarDireccion(1);
		}));
		this.subs.push(this.sessionServices.sessionObservable.subscribe(items => {
			if(Object.entries(items).length === 0) {
				this.router.navigate(['/login']);
			}
		}));
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	getPedidos() {
		this.loading = true;
		this.subs.push(this.servicios.getPedidos(this.idUsuario, this.idIntelisis)
		.subscribe(
		response => {
			this.loading = false;
			response.return.forEach((item: any) => {
				if(item.estatus_pedido != 6 && item.estatus_pedido != 4) {
					this.pedidosActivos.push(item);
				}
				if(item.estatus_pedido == 4) {
					this.pedidosTerminados.push(item);
				}
				if(item.estatus_pedido == 6) {
					this.pedidosCancelados.push(item);
				}
			});
			this.pageActivos.length = this.pedidosActivos.length;
			this.pageTerminados.length = this.pedidosTerminados.length;
			this.pageCancelado.length = this.pedidosCancelados.length;
		},
		error => {
			
		}));
	}

	consultaGuia() {

	}

	solicitarFactura(id: number) {

	}

	cancelarPedido(id: number) {

	}
}
