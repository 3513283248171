import { Component } from '@angular/core';
import { CarritoService } from 'src/app/core/servicios/carrito.service';

@Component({
	selector: 'app-confirmacion',
	templateUrl: './confirmacion.component.html',
	styleUrls: ['./confirmacion.component.scss']
})
export class ConfirmacionComponent {
	constructor(
		private carritoService: CarritoService
	) {
	}
	ngOnInit() {
		this.carritoService.emptyCarrito();
	}
}
