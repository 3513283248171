<div class="procesar-pago">
    <div class="center">
        <div class="img">
            <img src="../../../../assets/img/pago/check.svg" class="check-img">
            <div class="check-svg">
                <svg class="checkmark-pedido" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
            </div>
        </div>
        <h2 class="titulo">¡Gracias por tu compra!</h2>
        <p>
            Te enviaremos por correo electrónico con la información detallada de tu pedido.
        </p>
        <a routerLink="/cliente/mis-pedidos" class="button">Ver mi pedido</a>
    </div>
</div>