<div class="contInfo-cuenta">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="datos-cuenta">
        <div class="info">
            <h1>Datos de la cuenta</h1>
            <div class="cont-info">
                <div class="texto">
                    <p><b>Nombre:</b> {{infoCliente.nombre_cliente}}</p>
                    <p><b>Teléfono:</b> {{infoCliente.celular_cliente}}</p>
                    <p><b>Correo:</b> {{infoCliente.correo_cliente}}</p>
                </div>
                <div class="opciones full">
                    <a (click)="openModalEditar()" class="button circle" title="Editar información"><mat-icon>edit</mat-icon></a>
                    <a (click)="openModalCambiarPassword()" class="button circle" title="Cambiar contraseña"><mat-icon>password</mat-icon></a>
                    <a routerLink="/cliente/mis-pedidos" class="pedidos" title="Ver todos mis pedidos">
                        Mis pedidos
                        <span class="button circle"><mat-icon>shopping_basket</mat-icon></span>
                    </a>
                </div>
            </div>
            <div class="opciones">
                <a (click)="salir()" class="button alert">Cerrar sesión</a>
            </div>
        </div>
        <div class="facturacion">
            <h1>Datos de facturación</h1>
            <form [formGroup]="facturacionFormulario" (submit)="enviarDatosFacturacion()" class="formulario">
                <label for="" class="medium">
                    Nombre o Razón Social*
                    <input type="text" disabled [value]="facturacion.nombre_direccion_facturacion">
                </label>
                <label for="" class="medium">
                    RFC*
                    <input type="text" disabled [value]="facturacion.rfc_direccion_facturacion">
                </label>
                <label for="">
                    Dirección
                    <input type="text" formControlName="direccion_facturacion" [(ngModel)]="facturacion.direccion_facturacion">
                </label>
                <label for="">
                    Número Exterior
                    <input type="text" formControlName="n_ext_direccion_facturacion" [(ngModel)]="facturacion.n_ext_direccion_facturacion">
                </label>
                <label for="">
                    Número Interior
                    <input type="text" formControlName="n_int_direccion_facturacion" [(ngModel)]="facturacion.n_int_direccion_facturacion">
                </label>
                <label for="">
                    C.P.
                    <input type="text" formControlName="codigo_postal_direccion_facturacion" [(ngModel)]="facturacion.codigo_postal_direccion_facturacion" (change)="getCodigoPosta(facturacion.codigo_postal_direccion_facturacion)">
                </label>
                <label for="">
                    Colonia
                    <input type="text" formControlName="colonia_direccion_facturacion" [(ngModel)]="facturacion.colonia_direccion_facturacion" list="coloniasF">
                    <datalist id="coloniasF">
                        <option>Selecciona una colonia</option>    
                        <option *ngFor="let row of colonias">{{row.asentamiento}}</option>
                    </datalist>
                </label>
                <label for="">
                    Ciudad
                    <input type="text" formControlName="ciudad_direccion_facturacion" [(ngModel)]="facturacion.ciudad_direccion_facturacion">
                </label><label for="">
                    Estado
                    <input type="text" formControlName="estado_direccion_facturacion" [(ngModel)]="facturacion.estado_direccion_facturacion">
                </label>
                <button type="submit" [disabled]="facturacionFormulario.invalid" class="button">Actualizar datos</button>
            </form>
        </div>
    </div>
    <div class="direcciones">
        <h1>Direcciones de envío</h1>
        <a (click)="openModalDireccion()" class="button">Agregar direccón</a>
        <div class="lista-direcciones">
            <div class="item" *ngFor="let row of direcciones | paginate: { itemsPerPage: page.pageSize, currentPage: (page.pageIndex + 1) }; let key = index" [ngClass]="(row.default_direccion_envio == 1) ? 'active' : ''">
                <h1>{{row.nombre_direccion_envio}}</h1>
                <div class="texto">
                    <p>Calle: {{row.direccion_envio}}</p>
                    <p># exterior: {{row.n_ext_direccion_envio}}</p>
                    <p># interior: {{row.n_ext_direccion_envio}}</p>
                    <p>Colonia: {{row.colonia_direccion_envio}}</p>
                    <p>Municipio: {{row.ciudad_direccion_envio}}</p>
                    <p>Estado: {{row.estado_direccion_envio}}</p>
                </div>
                <div class="opciones">
                    <a (click)="openModalDireccion(row, 2, row.id_direccion_envio)" class="button circle" title="Editar"><mat-icon>edit</mat-icon></a>
                    <a (click)="openModalEliminarDireccion(row.id_direccion_envio)" class="button alert circle" title="Eliminar"><mat-icon>delete</mat-icon></a>
                </div>
                <a (click)="defaultDireccion(row.id_direccion_envio)">Elegir como dirección predeterminada</a>
            </div>
        </div>
        <mat-paginator 
            *ngIf="this.page.length > 2"
            (page)="page = $event"
            [length]="page.length"
            [pageSize]="page.pageSize"
            aria-label="Select page">
        </mat-paginator>
    </div>
</div>