<div class="modal">
    <a (click)="closeDialog()" class="close"><mat-icon>close</mat-icon></a>
    <h1>Agregar dirección</h1>
    <form [formGroup]="modalFormulario" (submit)="enviar()" class="formulario">
        <label for="">
            <span>Nombre de la dirección *</span>
            <input type="text" formControlName="nombre_direccion_envio" [(ngModel)]="repuesta.nombre_direccion_envio">
        </label>
        <label for="">
            <span>Dirección *</span>
            <input type="text" formControlName="direccion_envio" [(ngModel)]="repuesta.direccion_envio">
        </label>
        <label for="" class="medium">
            <span>Número exterior</span>
            <input type="text" formControlName="n_ext_direccion_envio" [(ngModel)]="repuesta.n_ext_direccion_envio">
        </label>
        <label for="" class="medium">
            <span>Número interior</span>
            <input type="text" formControlName="n_int_direccion_envio" [(ngModel)]="repuesta.n_int_direccion_envio">
        </label>
        <label for="" class="medium">
            <span>Código postal *</span>
            <input type="text" formControlName="codigo_postal_direccion_envio" [(ngModel)]="repuesta.codigo_postal_direccion_envio" (change)="getCodigoPosta(repuesta.codigo_postal_direccion_envio)">
        </label>
        <label for="" class="medium">
            <span>Ciudad *</span>
            <input type="text" formControlName="ciudad_direccion_envio" [(ngModel)]="repuesta.ciudad_direccion_envio">
        </label>
        <label for="" class="medium">
            <span>Colonia *</span>
            <input type="text" formControlName="colonia_direccion_envio" [(ngModel)]="repuesta.colonia_direccion_envio" list="coloniasE">
            <datalist id="coloniasE">
                <option>Selecciona una colonia</option>
                <option *ngFor="let row of colonias">{{row.asentamiento}}</option>
            </datalist>
        </label>
        <label for="" class="medium">
            <span>Estado *</span>
            <input type="text" formControlName="estado_direccion_envio" [(ngModel)]="repuesta.estado_direccion_envio">
        </label>
        <input type="hidden" formControlName="codigo_estado" ng-model="repuesta.codigo_estado">
        <input type="hidden" formControlName="codigo_municipio" ng-model="repuesta.codigo_municipio">
        <div class="grupo">
            <button type="submit" [disabled]="modalFormulario.invalid" class="button">Guardar</button>
        </div>
    </form>
</div>