import { Options } from '@angular-slider/ngx-slider';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-categoria',
	templateUrl: './categoria.component.html',
	styleUrls: ['./categoria.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('500ms', style({ opacity: 0 }))
			])
		])
	]
})
export class CategoriaComponent implements OnInit, OnDestroy {
	options: Options = {
	  floor: 0,
	  ceil: 0
	};
	filtros: any = {
		minValue: 0,
		maxValue: 0,
		categoria: "",
		material: "",
		promocion: "",
		destacado: "",
		ordenar: 1,
		promocionActivo: false,
		destacadoActivo: false,
		categoriaActivo: false,
		materialActivo: false,
		precioActivo: true,
		// coloresActivo: false
	};
	
	promocion: boolean = false;
	destacado: boolean = false;
	categorias: any = [];
	materiales: any = [];
	url = environment.APIEndpoint + '/assets/productos';
	default = environment.APIEndpoint + '/assets/img/default.png';
	productos: any = [];
	buscarItems: string = '';
	categoria: any = {};
	loading: boolean = false;
	productosAux: any = [];
	sinEncontrar: boolean = false;
	dataSite: any = {};
	// colores: any = [];
	page: any = {
		length: 0,
		pageIndex: 0,
		pageSize: 12,
		previousPageIndex: 0
	};
	ordenar: number = 1;
	filtroActivo: boolean = false;
	constructor(
		private servicios: ApiService,
		private route: ActivatedRoute,
		private serviciosSession: SessionService
	) { 
		this.subs.push(serviciosSession.datosSiteObs.subscribe(items => {
			this.dataSite = items;
		}));
	}

	ngOnInit() {
		this.subs.push(this.route.params.subscribe(
			params => {
				console.log(params);
				if(params['idCategoria']) {
					this.getCategoriaProductos(params['idCategoria']);
				}
			}
		));
		this.subs.push(
			this.route.queryParams.subscribe(params => {
				if(params['subcategoria']){
					this.filtros.categoria = params['subcategoria'];
				} else {
					this.filtros.categoria = '';
				}
			})
		);
		this.subs.push(this.route.queryParamMap
			.subscribe((params: any) => {
				console.log(params);
				if(params.params.buscar) {
					this.getCategoriaProductos(0, params.params.buscar);
				}
			}
		));
	}

	private subs: Subscription[] = [];

	changePromocion() {
		if(this.promocion) {
			this.filtros.promocion = 'si';
		} else {
			this.filtros.promocion = '';
		}
	}

	changeDestacado() {
		if(this.destacado) {
			this.filtros.destacado = 'si';
		} else {
			this.filtros.destacado = '';
		}
	}

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	getCategoriaProductos($id: number, $buscar: string = '') {
		this.loading = true;
		this.sinEncontrar = false;
		if($buscar) {
			this.categoria.nombre_categoria = 'Busqueda';
		} else {
			this.subs.push(this.servicios.getCategoria($id)
			.subscribe(
				response => {
					this.categoria = response.return;
				}, 
				error => {

				}
			));
		}
		this.subs.push(this.servicios.getCategoriaProductos($id, $buscar)
		.subscribe(
			response => {
				this.productos = response.productos;
				if(this.productos.length > 0) {
					let mayor = Math.ceil(this.productos.sort((a:any, b:any) => b.precio_mayoreo - a.precio_mayoreo)[0].precio_mayoreo);
					this.filtros.maxValue = mayor;
					this.categorias = response.categorias;
					this.materiales = response.materiales;
					this.setNewCeil(mayor);
					// this.colores = response.colores;
					this.productosAux = response.productos;
					this.page.length = this.productos.length;
				} else {
					this.sinEncontrar =  true;
				}
				this.loading = false;
			},
			error => {
				console.log(error);
				this.loading = false;
			}
		));
	}

	filterItems(items: any[], filtros: any): any[] {
		items = items.filter(p => p.precio_mayoreo <= filtros.maxValue && p.precio_mayoreo >= filtros.minValue);
		if(filtros.promocion) {
			items = items.filter(p => p.promocion.toLowerCase() == filtros.promocion.toLowerCase());
		}
		if(filtros.material) {
			items = items.filter(p => p.material.toLowerCase() == filtros.material.toLowerCase());
		}
		if(filtros.destacado) {
			items = items.filter(p => p.articulo_destacado.toLowerCase() == filtros.destacado.toLowerCase());
		}
		if(filtros.categoria) {
			items = items.filter(p => p.categoria.toLowerCase() == filtros.categoria.toLowerCase());
		}
		if(filtros.ordenar == 2) {
			items = items.sort((a:any, b:any) => a.precio_mayoreo - b.precio_mayoreo);
		}
		if(filtros.ordenar == 3) {
			items = items.sort((a:any, b:any) => b.precio_mayoreo - a.precio_mayoreo);
		}
		this.page.length = items.length;
		return items;
	}

	setNewCeil(newCeil: number): void {
		// Due to change detection rules in Angular, we need to re-create the options object to apply the change
		const newOptions: Options = Object.assign({}, this.options);
		newOptions.ceil = newCeil;
		this.options = newOptions;
	}
}
