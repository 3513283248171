<div class="login-usaurio">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="login">
        <h1>Iniciar sesión</h1>
        <form [formGroup]="formLogin" (ngSubmit)="onSubmitLogin()">
            <label for="">
                Correo electrónico
                <input type="text" formControlName="usuario">
            </label>
            <label for="">
                Contraseña
                <input type="password" formControlName="password">
            </label>
            <re-captcha formControlName="recaptchaReactive"></re-captcha>
            <a routerLink="/cliente/recuperar-password">¿Olvidaste tu contraseña?</a>
            <button class="button" type="submit" [disabled]="formLogin.invalid">Iniciar sesion</button>
            <p>Al continuar estas aceptando nuestros <b>Términos y Condiciones</b></p>
        </form>
    </div>
    <div class="cuenta">
        <h1>Crea una cuenta</h1>
        <div class="opcion">
            <a routerLink="/registro" class="button">Crea una cuenta</a>
            <p>No te precupes, puedes registrarte de una forma muy facil, no te pierdas de la oportunidad de comprar con nosotros.</p>
        </div>
    </div>
</div>