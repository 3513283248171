<div class="banner">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let row of banners" class="slide">
            <a href="{{row.datos_extra_archivo.url}}" *ngIf="row.datos_extra_archivo.url"></a>
            <!-- <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'" 
                [srcset]="[url+'/'+row.path_archivo+'/750_'+row.file_name_archivo+'.webp 30vw', url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp 100vw']"
                sizes="(max-width: 520px) 200px, (max-width: 1200px) 1000px"
                alt="" src-fallback="{{ default }}"> -->
            <picture>
                <source media="(max-width: 520px)" [srcset]="url+'/'+row.path_archivo+'/750_'+row.file_name_archivo+'.webp'">
                <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'">
            </picture>
            <!-- <div class="contenedor">
                <div class="texto">
                    <h1 class="titulo-t">DÉJATE CONSENTIR</h1>
                    <p>POR LA VERDADERA EXPERIENCIA EN BIENES RAÍCES</p>
                    <a (click)="openModal()" class="button clear">CONOCE MÁS</a>
                </div>
            </div> -->
        </div>
    </ngx-slick-carousel>
</div>

<div class="banner">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let row of bannersPromocion" class="slide">
            <a href="{{row.datos_extra_archivo.url}}" *ngIf="row.datos_extra_archivo.url"></a>
            <!-- <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'" 
                [srcset]="[url+'/'+row.path_archivo+'/750_'+row.file_name_archivo+'.webp 30vw', url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp 100vw']"
                sizes="(max-width: 520px) 200px, (max-width: 1200px) 1000px"
                alt="" src-fallback="{{ default }}"> -->
            <picture>
                <source media="(max-width: 520px)" [srcset]="url+'/'+row.path_archivo+'/750_'+row.file_name_archivo+'.webp'">
                <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'">
            </picture>
            <!-- <div class="contenedor">
                <div class="texto">
                    <h1 class="titulo-t">DÉJATE CONSENTIR</h1>
                    <p>POR LA VERDADERA EXPERIENCIA EN BIENES RAÍCES</p>
                    <a (click)="openModal()" class="button clear">CONOCE MÁS</a>
                </div>
            </div> -->
        </div>
    </ngx-slick-carousel>
</div>

<!--<div class="cont-items">
    -- <h1>Lo más nuevo <span>(59)</span></h1> --
    <h1>Producto destacado</h1>
    <div class="items" [ngClass]="loNuevo.slice(0, 4).length == 4 ? 'full' : ''">
        <a [routerLink]="['/producto/', rowProducto.categorias[0].id, rowProducto.codigo]" class="item" *ngFor="let rowProducto of loNuevo.slice(0, 4);">
            <div class="img">
                -- <img [src]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'" alt="" src-fallback="{{ default }}"> --
                                <picture>
                    <source media="(max-width: 520px)" [srcset]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'">
                    <img [src]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'">
                </picture>
            </div>
            <div class="info">
                <div class="colores">
                    <span *ngFor="let rowColor of rowProducto.colores.slice(0, 5)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]></span>
                    <span class="mas" *ngIf="rowProducto.colores.length > 5" [@fadeInOut]>15+</span>
                </div>
                <p>{{rowProducto.nombre}}</p>
                <p>Código: {{rowProducto.codigo}}</p>
                -- <p class="precio">{{rowProducto.unidad}}: {{rowProducto.precio_neto | currency}} MXN</p> --
                <p class="precio">{{rowProducto.unidad_mayoreo}}: {{rowProducto.precio_neto * rowProducto.factor_web | currency}} MXN</p>
                -- <p class="precio descuento">Pieza $17.50 MXN</p> --
            </div>
        </a>
    </div>
    -- <a href="" class="button">Ver más</a> --
</div>-->


<div class="categorias">
    <a [routerLink]="['/categoria', row.categoria.nombre_categoria_slug, row.categoria.id_categoria]" class="item" *ngFor="let row of categorias">
        <!-- <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'" alt="" src-fallback="{{ default }}"> -->
        <picture>
            <source media="(max-width: 520px)" [srcset]="url+'/'+row.path_archivo+'/750_'+row.file_name_archivo+'.webp'">
            <img [src]="url+'/'+row.path_archivo+'/'+row.file_name_archivo+'.webp'">
        </picture>
        <div class="texto">
            <h1>{{row.categoria.nombre_categoria | lowercase}}</h1>
        </div>
    </a>
</div>


<div class="cont-items">
     <!--<h1>Promociones <span>(59)</span></h1> -->
    <h1>Promociones</h1>
    <div class="items" [ngClass]="promociones.slice(0, 4).length == 4 ? 'full' : ''">
        <a [routerLink]="['/producto/', rowProducto.categorias[0].id, rowProducto.codigo]" class="item" *ngFor="let rowProducto of promociones.slice(0, 4);">
            <div class="img">
                 <img [src]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'" alt="" src-fallback="{{ default }}">    
                <picture>
                    <source media="(max-width: 520px)" [srcset]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'">
                 <!--   <img [src]="urlImg+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'">-->
                </picture>
            </div>
            <div class="info">
                <!--<div class="colores">
                    <span *ngFor="let rowColor of rowProducto.colores.slice(0, 5)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]></span>
                    <span class="mas" *ngIf="rowProducto.colores.length > 5" [@fadeInOut]>15+</span>
                </div>-->
                <p>{{rowProducto.nombre}}</p>
                <p>Código: {{rowProducto.codigo}}</p>
                <!--<p class="precio">{{rowProducto.unidad}}: {{rowProducto.precio_neto | currency}} MXN</p>-->
                <p class="precio">{{rowProducto.unidad_mayoreo}}: {{rowProducto.precio_neto * rowProducto.factor_web | currency}} MXN</p>
                <!-- <p class="precio descuento">Pieza $17.50 MXN</p> -->
            </div>
        </a>
    </div>
    <!-- <a href="" class="button">Ver más</a> -->
</div>
