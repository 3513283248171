import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-modal-politicas',
	templateUrl: './modal-politicas.component.html',
	styleUrls: ['./modal-politicas.component.scss']
})
export class ModalPoliticasComponent {
	repuesta: any = {};
	constructor(
		public dialogRef: MatDialogRef<ModalPoliticasComponent>,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
	) {
		this.repuesta.texto = dataDialog.info;
	 }

	closeDialog() {
		this.dialogRef.close();
	}
}
