import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/servicios/api.service';
import { CarritoService } from 'src/app/core/servicios/carrito.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { SessionService } from 'src/app/core/servicios/session.service';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-perfil',
	templateUrl: './perfil.component.html',
	styleUrls: ['./perfil.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('500ms', style({ opacity: 0 }))
			])
		])
	]
})
export class PerfilComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	url = environment.APIEndpoint + '/assets/productos/';
	default = environment.APIEndpoint + '/assets/img/default.png';

	producto: any = {};
	categoria: any = {};
	precio_producto = 0;
	itemsToShow = 10;
	cantidadProducto: number = 0;
	opcionOpen = 0;
	colores: any[] = [];

	imagen_activa: string = '';
	productoSeleccionado: any = {};
	productoTallas: any = {};
	productoTallaSeleccionado: string = "";
	tallasActivo: boolean = false;
	coloresSeleccion: string = "";
	cantidad_disponible_factorweb: number = 0;
	productosSimilares: any[] = [];
	productosComplementarios: any[] = [];
	alerts: any[] = [];
	dataSite: any = {};

	constructor(
		private servicios: ApiService,
		private servicioCarrito: CarritoService,
		private route: ActivatedRoute,
		private router: Router,
		private meta: Meta,
		private titulo: Title,
		@Inject(DOCUMENT) document: any,
		private sessionService: SessionService
	) { }

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	ngOnInit() {
		this.subs.push(this.route.params.subscribe(
			params => {
				this.getProducto(params['idCategoria'], params['codigo']);
				//this.getPrueba();
			}
		));
		this.subs.push(this.sessionService.datosSiteObs.subscribe(items => {
			this.dataSite = items;
		}));
	}

	// getPrueba($id: any = 6) {
	// 	this.servicios.getCategoriaProductos($id)
	// 	.subscribe(
	// 		response => {
	// 			this.productosSimilares = response.productos;
	// 			this.productosComplementarios = response.productos;
	// 		},
	// 		error => {
	// 			console.log(error);
	// 		}
	// 	);
	// }

	generarMetas(data: any, img: any, url: string) {
		this.titulo.setTitle('Assis - '+data.descripcion.toLowerCase());
		/* 
			<meta property="og:price:amount" content="<?= number_format($precio, 2, '.', ''); ?>"/>
			  <meta property="og:price:currency" content="MEX"/>
		*/
		this.meta.updateTag(
			{
				property: 'og:description',
				content: data.nombre
			}
		);
		this.meta.updateTag(
			{
				name: 'description',
				content: data.nombre
			}
		)
		this.meta.updateTag(
			{
				property: 'description',
				content: data.nombre
			},
		)

		this.meta.updateTag(
			{
				property: 'og:image',
				content: img
			}
		);
		this.meta.updateTag(
			{
				itemprop: 'image',
				content: img
			}
		)
		this.meta.updateTag(
			{
				property: 'og:url',
				content: url
			},
		)
		//this.meta.updateTag({});
	}

	getProducto(idCategoria: number, idProducto: number) {
		this.loading = true;
		this.subs.push(this.servicios.getProducto(idCategoria, idProducto)
			.subscribe(
				response => {
					this.producto = response.producto;
					this.colores = this.producto.colores;
					this.imagen_activa = this.url + this.producto.codigo + '/750_' + this.producto.codigo +'.webp'+ `?v=${Math.random()}`;
					this.categoria = response.categoria;
					if(this.colores[0]) {
						this.changeColor(this.colores[0]);
					}
					/* [{id: "10187", nombre: "CONO HILO CLAUDIA TEXT 40 (30/2) CAL 80C/1 600 MTS"}] */
					this.getProductosSimilares(this.producto.productos_similares);
					this.getProductosComplementarios(this.producto.productos_complementarios);
					this.loading = false;
					this.generarMetas(this.producto, this.imagen_activa, document.location.href);
				},
				error => {
					console.log(error);
					this.loading = false;
				}
			));
	}

	getProductosSimilares(ids: any) {
		this.subs.push(this.servicios.getProductosSimples(ids)
			.subscribe(
				response => {
					this.productosSimilares = response.return;
				},
				error => {
					console.log(error);
				}
			));
	}

	getProductosComplementarios(ids: any) {
		this.subs.push(this.servicios.getProductosSimples(ids)
			.subscribe(
				response => {
					this.productosComplementarios = response.return;
				},
				error => {
					console.log(error);
				}
			));
	}


	changeColor(item: any) {
		this.alerts = [];
		this.iniciarVariables();
		this.coloresSeleccion = item.id;
		this.imagen_activa = this.url + this.producto.codigo + '/750_' + this.producto.codigo + '-' + this.coloresSeleccion + '.webp'+ `?v=${Math.random()}`;
		this.precio_producto = item.precio_neto;
		this.productoSeleccionado = item;
		/* ver si tiene tallas o opciones */
		if(item.tallas) {
			if(item.tallas.length > 0) {
				this.productoTallas = item.tallas;
				this.tallasActivo = true;
			}
		}
		let num = (this.producto.factor_web) ? this.producto.factor_web : 0;
		if(item.disponible != 0 && num != 0) {
			this.cantidad_disponible_factorweb = Math.floor(item.disponible/num);
		} else {
			this.cantidad_disponible_factorweb = 0;
		}
		if(this.cantidad_disponible_factorweb == 0) {
			console.log('entro');
			//this.addAlert($color.color);
			console.log(item);
			this.alerts.push(
				{
					type: 'alert',
					msg: "Para solicitar existencia del producto puedes enviar un mensaje por",
					msgWhatsapp: encodeURIComponent('Me gustaría saber sobre la existencia de este producto: '+ this.producto.nombre.toLowerCase() +', color:'+ item.color.toLowerCase()),
				}
			);
		}
	}

	opcionCantidad(opcion: number) {
		if(opcion == 1) {
			this.cantidadProducto ++;
			this.cambiarCantidadProducto();
		}
		if(opcion == 0) {
			if(this.cantidadProducto > 0) {
				this.cantidadProducto --;
			}
		}
	}

	cambiarCantidadProducto() {
		let mult = this.cantidad_disponible_factorweb;
		let cantidad = this.cantidadProducto;
		/*$multiplo = false;
		while ($multiplo != true) {

			if (Number.isInteger(parseInt(cantidad) / parseInt(mult))) {
				$multiplo = true;
			} else {
				cantidad = parseInt(cantidad) + 1;
			}
			console.log('entro');
		}*/
		//this.cantidadProducto = cantidad;
		if(cantidad > mult) {
			this.cantidadProducto = mult;
		}
	}

	iniciarVariables(): void {
		this.productoSeleccionado = {};
		this.productoTallaSeleccionado = '';
		this.productoTallas = {};
		this.tallasActivo = false;
		this.cantidadProducto = 0;
		this.cantidad_disponible_factorweb = 0;
	}
	
	agregarCarrito($enviar = false) {
		//this.errorProducto.activo = false;
		if (this.cantidadProducto > 0) {
			if (this.productoSeleccionado.id) {
				let producto = {
					'id': this.productoSeleccionado.id+'-'+this.producto.codigo,
					'qty': this.cantidadProducto,
					'price': (this.productoSeleccionado.precio_neto * this.producto.factor_web),
					'name': this.productoSeleccionado.color,
				};
				let producto_options = {
					'img_producto': this.imagen_activa,
					'codigo_producto': this.producto.codigo,
					'opcion': this.productoSeleccionado.id,
					//'url_producto': '<?php echo site_url(); ?>/producto/'+ this.producto.nombre + '-' + this.producto.id_producto,
					'peso_producto': this.producto.peso,
					'peso_unidad': this.producto.peso_unidad,
					'profundidad': this.producto.profundidad,
					'volumen': this.producto.volumen,
					'alto': this.producto.alto,
					'ancho': this.producto.ancho,
					'devolucion': '',
					'descuento_producto': '',
					'diferencia_descuento': '',
					'opcion_talla': (this.productoTallaSeleccionado) ? this.productoTallaSeleccionado : '',
					'unidad': this.producto.unidad,
					'unidad_mayoreo': this.producto.unidad_mayoreo,
					'cantidad_caja': this.producto.factor_web,
					'minimo_compra': this.producto.minimo_compra,
					'almacen': this.producto.alm_web,
					//'cantidad_total': parseFloat(this.minimo_compra) * parseFloat(this.cantidadProducto),
					'precio': this.productoSeleccionado.precio_neto,
					'precio_sin_iva': this.productoSeleccionado.precio,
					'nombre_producto': this.producto.nombre,
					'nombre_categoria': this.categoria.nombre_categoria,
					'cantidad_disponible_factorweb': this.cantidad_disponible_factorweb
				};

				this.subs.push(this.servicioCarrito.addItem(producto, producto_options)
					.subscribe(
					response => {
						//console.log(response);
						setTimeout(() => {
							this.router.navigate(['/carrito']);
						}, 100);
					},
					error => {
						console.log(error);
					}
				));
			} else {
				// angular.element('.loading').removeClass('active');
				// this.errorProducto.activo = true;
				// this.errorProducto.mensaje = 'Tiene que seleccionar un producto.';
			}
		} else {
			// angular.element('.loading').removeClass('active');
			// this.errorProducto.activo = true;
			// this.errorProducto.mensaje = 'Tiene que seleccionar una cantidad.';
		}
	}

	slides = [
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
		{ img: './assets/img/Enmascarar grupo 6@2x.png' },
	];

	slideConfigVertical = {
		slidesToShow: 2,
		slidesToScroll: 1,
		//autoplay: true,
		vertical: true,
		verticalSwiping: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 1421,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					vertical: false,
					verticalSwiping: false,
				}
			}
		]
	};

	slideConfig = {
		slidesToShow: 4,
		slidesToScroll: 1,
		//autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 1030,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

}
