<div class="informacion-pedidos">
    <h1>Mis pedidos</h1>
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="tabs">
        <mat-tab-group animationDuration="10ms">
            <mat-tab label="Mis pedidos activos">
                <div class="lista-items" *ngIf="pedidosActivos.length > 0">
                    <div class="item"
                        *ngFor="let row of pedidosActivos | paginate: { itemsPerPage: pageActivos.pageSize, currentPage: (pageActivos.pageIndex + 1) }">
                        <div class="head">
                            <h1>Pedido #{{row.id_pedido}}</h1>
                            <span *ngIf="row.mensaje_intelisis">Intelisis: #{{row.mensaje_intelisis.data[0].id}}</span>
                        </div>
                        <div class="informacion">
                            <div class="info-pedido">
                                <p>Fecha del pedido: <b>{{row.fecha_pago}}</b></p>
                                <p>
                                    Total: <b>{{row.total | currency}}</b>
                                    <img src="../../../../../assets/img/pago/comisiones_tarjetas.png" id="tarjeta"
                                        *ngIf="row.tipo_pago == 6">
                                    <img src="../../../../../assets/img/pago/logo_paynet1.png" id="paynet"
                                        *ngIf="row.tipo_pago == 7">
                                    <img src="../../../../../assets/img/pago/transferencia_comision_spei.png"
                                        *ngIf="row.tipo_pago == 8" id="transferencia_comision_spei">
                                </p>
                                <div class="estatus">
                                    <h5>Estatus</h5>
                                    <div class="wrap-progress">
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 1 || row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Activo</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>En proceso</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Envio</span>
                                        </div>
                                        <div class="progress" [ngClass]="(row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Finalizado</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="datos-envio-opciones">
                                <div class="texto">
                                    <p>Dirección de envío: Calle {{row.info_envio.calle}}
                                        {{row.info_envio.colonia_direccion_envio}} CP
                                        {{row.info_envio.codigo_postal_direccion_envio}}</p>
                                    <p *ngIf="row.intelisis.activo == 0">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.codigo_rastreo}}</a></b></p>
                                    <p *ngIf="row.intelisis.activo == 1">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.intelisis.data.guia[0].codigo_rastreo}}</a></b>
                                    </p>
                                </div>
                                <div class="opciones">
                                    <h5>Opciones</h5>
                                    <div class="btns">
                                        <a *ngIf="row.estatus_pago == 0 && (row.estatus_pedido != 6 && row.estatus_pedido != 4)"
                                            target="_blank"
                                            href="{{urlPdfOpenPay}}/{{(row.tipo_pago == 7) ? 'paynet' : ''}}{{(row.tipo_pago == 8) ? 'spei' : ''}}-pdf/{{idOpenPay}}/{{row.referencia_pago}}"
                                            class="button clear">Ver formato de pago</a>
                                        <a *ngIf="row.estatus_pago == 1 && row.solicitar_factura == 0"
                                            (click)="solicitarFactura(row.id_pedido)" class="button clear">Solicitar
                                            factura</a>
                                        <a target="_blank" href="{{urlPedido}}/pedidos/ver-pedido/{{row.id_pedido}}"
                                            class="button clear">Ver detalle del pedido</a>
                                        <a *ngIf="row.estatus_pedido == 1" (click)="cancelarPedido(row.id_pedido)"
                                            class="button alert">Cancelar pedido</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-paginator (page)="pageActivos = $event" [length]="pageActivos.length"
                    [pageSize]="pageActivos.pageSize" *ngIf="pedidosActivos.length > 0">
                </mat-paginator>
            </mat-tab>
            <mat-tab label="Historial de compras">
                <div class="lista-items" *ngIf="pedidosTerminados.length > 0">
                    <div class="item"
                        *ngFor="let row of pedidosTerminados | paginate: { itemsPerPage: pageTerminados.pageSize, currentPage: (pageTerminados.pageIndex + 1) }">
                        <div class="head">
                            <h1>Pedido #{{row.id_pedido}}</h1>
                            <span *ngIf="row.mensaje_intelisis">Intelisis: #{{row.mensaje_intelisis.data[0].id}}</span>
                        </div>
                        <div class="informacion">
                            <div class="info-pedido">
                                <p>Fecha del pedido: <b>{{row.fecha_pago}}</b></p>
                                <p>
                                    Total: <b>{{row.total | currency}}</b>
                                    <img src="../../../../../assets/img/pago/comisiones_tarjetas.png" id="tarjeta"
                                        *ngIf="row.tipo_pago == 6">
                                    <img src="../../../../../assets/img/pago/logo_paynet1.png" id="paynet"
                                        *ngIf="row.tipo_pago == 7">
                                    <img src="../../../../../assets/img/pago/transferencia_comision_spei.png"
                                        *ngIf="row.tipo_pago == 8" id="transferencia_comision_spei">
                                </p>
                                <div class="estatus">
                                    <h5>Estatus</h5>
                                    <div class="wrap-progress">
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 1 || row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Activo</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>En proceso</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Envio</span>
                                        </div>
                                        <div class="progress" [ngClass]="(row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Finalizado</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="datos-envio-opciones">
                                <div class="texto">
                                    <p>Dirección de envío: Calle {{row.info_envio.calle}}
                                        {{row.info_envio.colonia_direccion_envio}} CP
                                        {{row.info_envio.codigo_postal_direccion_envio}}</p>
                                    <p *ngIf="row.intelisis.activo == 0">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.codigo_rastreo}}</a></b></p>
                                    <p *ngIf="row.intelisis.activo == 1">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.intelisis.data.guia[0].codigo_rastreo}}</a></b>
                                    </p>
                                </div>
                                <div class="opciones">
                                    <h5>Opciones</h5>
                                    <div class="btns">
                                        <a *ngIf="row.estatus_pago == 0 && (row.estatus_pedido != 6 && row.estatus_pedido != 4)"
                                            target="_blank"
                                            href="{{urlPdfOpenPay}}/{{(row.tipo_pago == 7) ? 'paynet' : ''}}{{(row.tipo_pago == 8) ? 'spei' : ''}}-pdf/{{idOpenPay}}/{{row.referencia_pago}}"
                                            class="button clear">Ver formato de pago</a>
                                        <a *ngIf="row.estatus_pago == 1 && row.solicitar_factura == 0"
                                            (click)="solicitarFactura(row.id_pedido)" class="button clear">Solicitar
                                            factura</a>
                                        <a target="_blank" href="{{urlPedido}}/pedidos/ver-pedido/{{row.id_pedido}}"
                                            class="button clear">Ver detalle del pedido</a>
                                        <a *ngIf="row.estatus_pedido == 1" (click)="cancelarPedido(row.id_pedido)"
                                            class="button alert">Cancelar pedido</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-paginator (page)="pageTerminados = $event" [length]="pageTerminados.length"
                    [pageSize]="pageTerminados.pageSize" *ngIf="pedidosTerminados.length > 0">
                </mat-paginator>
            </mat-tab>
            <mat-tab label="Cancelados">
                <div class="lista-items" *ngIf="pedidosCancelados.length > 0">
                    <div class="item" *ngFor="let row of pedidosCancelados | paginate: { itemsPerPage: pageCancelado.pageSize, currentPage: (pageCancelado.pageIndex + 1) }">
                        <div class="head">
                            <h1>Pedido #{{row.id_pedido}}</h1>
                            <span *ngIf="row.mensaje_intelisis">Intelisis: #{{row.mensaje_intelisis.data[0].id}}</span>
                        </div>
                        <div class="informacion">
                            <div class="info-pedido">
                                <p>Fecha del pedido: <b>{{row.fecha_pago}}</b></p>
                                <p>
                                    Total: <b>{{row.total | currency}}</b>
                                    <img src="../../../../../assets/img/pago/comisiones_tarjetas.png" id="tarjeta"
                                        *ngIf="row.tipo_pago == 6">
                                    <img src="../../../../../assets/img/pago/logo_paynet1.png" id="paynet"
                                        *ngIf="row.tipo_pago == 7">
                                    <img src="../../../../../assets/img/pago/transferencia_comision_spei.png"
                                        *ngIf="row.tipo_pago == 8" id="transferencia_comision_spei">
                                </p>
                                <div class="estatus">
                                    <h5>Estatus</h5>
                                    <div class="wrap-progress">
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 1 || row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Activo</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>En proceso</span>
                                        </div>
                                        <div class="progress"
                                            [ngClass]="(row.estatus_pedido == 2 || row.estatus_pedido == 3 || row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Envio</span>
                                        </div>
                                        <div class="progress" [ngClass]="(row.estatus_pedido == 4) ? 'success' : ''">
                                            <span>Finalizado</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="datos-envio-opciones">
                                <div class="texto">
                                    <p>Dirección de envío: Calle {{row.info_envio.calle}}
                                        {{row.info_envio.colonia_direccion_envio}} CP
                                        {{row.info_envio.codigo_postal_direccion_envio}}</p>
                                    <p *ngIf="row.intelisis.activo == 0">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.codigo_rastreo}}</a></b></p>
                                    <p *ngIf="row.intelisis.activo == 1">Número de guía: <b><a
                                                ng-click="consultaGuia()">{{row.intelisis.data.guia[0].codigo_rastreo}}</a></b>
                                    </p>
                                </div>
                                <div class="opciones">
                                    <h5>Opciones</h5>
                                    <div class="btns">
                                        <a *ngIf="row.estatus_pago == 0 && (row.estatus_pedido != 6 && row.estatus_pedido != 4)"
                                            target="_blank"
                                            href="{{urlPdfOpenPay}}/{{(row.tipo_pago == 7) ? 'paynet' : ''}}{{(row.tipo_pago == 8) ? 'spei' : ''}}-pdf/{{idOpenPay}}/{{row.referencia_pago}}"
                                            class="button clear">Ver formato de pago</a>
                                        <a *ngIf="row.estatus_pago == 1 && row.solicitar_factura == 0"
                                            (click)="solicitarFactura(row.id_pedido)" class="button clear">Solicitar
                                            factura</a>
                                        <a target="_blank" href="{{urlPedido}}/pedidos/ver-pedido/{{row.id_pedido}}"
                                            class="button clear">Ver detalle del pedido</a>
                                        <a *ngIf="row.estatus_pedido == 1" (click)="cancelarPedido(row.id_pedido)"
                                            class="button alert">Cancelar pedido</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-paginator (page)="pageCancelado = $event" [length]="pageCancelado.length"
                    [pageSize]="pageCancelado.pageSize" *ngIf="pedidosCancelados.length > 0">
                </mat-paginator>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>