import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

	private sessionObservablePrivete: BehaviorSubject<any> =
			new BehaviorSubject<any>({});
	
	private sessionPago: BehaviorSubject<any> =
			new BehaviorSubject<any>({});
	
	public datosSite: BehaviorSubject<any> =
			new BehaviorSubject<any>({});

	dataSave(data: any) {
      	window.localStorage.setItem('usuario', JSON.stringify(data));
	}

	getDataSave() {
		var str: any = window.localStorage.getItem('usuario');
		if(str) {
			var items = JSON.parse(str);
			this.sessionObservablePrivete.next(items);
		} else {
			this.sessionObservablePrivete.next({});
		}
		return items;
	}

	dataSaveInfo(data: any) {
		window.localStorage.setItem('dataInfo', JSON.stringify(data));
	}

	getDataInfoSave() {
		var str: any = window.localStorage.getItem('dataInfo');
		if(str) {
			var items = JSON.parse(str);
			this.sessionPago.next(items);
		} else {
			this.sessionPago.next({});
		}
		return items;
	}

	logout() {
		this.sessionObservablePrivete.next({});
		return this.sessionObservablePrivete.asObservable();
	}

	get sessionObservable() {
		return this.sessionObservablePrivete.asObservable();
	}

	set sessionObservableData(data: any) {
		this.sessionObservablePrivete.next(data);
	}

	get sessionInfoObservable() {
		return this.sessionPago.asObservable();
	}

	set sessionInfoObservableData(data: any) {
		this.sessionPago.next(data);
	}

	get datosSiteObs() {
		return this.datosSite.asObservable();
	}

	set datosSiteObsData(data: any) {
		this.datosSite.next(data);
	}

	limpiarInfo() {
		this.sessionPago.next({});
		return this.sessionPago.asObservable();
	}
}
