<div class="modal">
    <a (click)="closeDialog()" class="close"><mat-icon>close</mat-icon></a>
    <h1>Modificar contraseña actual</h1>
    <form [formGroup]="modalFormulario" (submit)="enviar()" class="formulario">
        <label for="">
            <span>Contraseña actual *</span>
            <input type="text" formControlName="password_actual" [(ngModel)]="repuesta.password_actual">
        </label>
        <label for="">
            <span>Nueva contraseña *</span>
            <input type="text" formControlName="password_cliente" [(ngModel)]="repuesta.password_cliente">
        </label>
        <div class="grupo">
            <button type="submit" [disabled]="modalFormulario.invalid" class="button">Guardar</button>
        </div>
    </form>
</div>