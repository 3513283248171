<div class="categoria">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="info-categoria">
        <div class="titulo-buscador">
            <h1>{{categoria.nombre_categoria | lowercase}} <span>({{productos.length}})</span></h1>
            <label for="">
                <input type="text" placeholder="Buscar por nombre" [(ngModel)]="buscarItems">
            </label>
        </div>
        <div class="ordenar">
            <label for="">
                <span>Ordenar por precio:</span>
                <select [(ngModel)]="filtros.ordenar">
                    <option [value]="1">Seleccionar Orden</option>
                    <option [value]="2">Menor a mayor</option>
                    <option [value]="3">Mayor a menor</option>
                </select>
            </label>
        </div>
    </div>
    <div class="filtro-items">
        <div class="filtros">
            <h1>
                Filtar por 
                <a (click)="filtroActivo = !filtroActivo" class="filtrar">
                    <mat-icon>filter_alt</mat-icon>
                </a>
            </h1>
            <div class="acoordion" [ngClass]="(filtroActivo == true) ? 'activo' : ''">
                <div class="item">
                    <!-- <div class="header-acoordion" (click)="filtros.promocionActivo = !filtros.promocionActivo"> -->
                    <div class="header-acoordion">
                        <h1>Promociones</h1>
                        <!-- <span>+</span> -->
                        <label class="checkBox" for="promocion">
                            <input type="checkbox" id="promocion" [(ngModel)]="promocion" (change)="changePromocion()"  [ngModelOptions]="{standalone: true}">
                            <span class="checkmarkBox" for="promocion"></span>
                        </label>
                    </div>
                    <!-- <div class="contenido-acoordion" *ngIf="filtros.promocionActivo">
                        <div class="lista-seleccionable">
                            <a (click)="filtros.promocion = ''" [ngClass]="filtros.promocion == '' ? 'activo' : ''">
                                <mat-icon>done</mat-icon>
                                Todos
                            </a>
                            <a (click)="filtros.promocion = 'Si'" [ngClass]="filtros.promocion == 'Si' ? 'activo' : ''">
                                <mat-icon>done</mat-icon>
                                Si
                            </a>
                            <a (click)="filtros.promocion = 'No'" [ngClass]="filtros.promocion == 'No' ? 'activo' : ''">
                                <mat-icon>done</mat-icon>
                                No
                            </a>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="item">
                    <a class="header-acoordion" (click)="filtros.coloresActivo = !filtros.coloresActivo">
                        <h1>Colores</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion" *ngIf="filtros.coloresActivo">
                        <div class="colores">
                            <span *ngFor="let rowColor of colores" [ngStyle]="{'background-color': rowColor}" [@fadeInOut]>
                                <mat-icon>done</mat-icon>
                            </span>
                        </div>
                    </div>
                </div> -->
                <div class="item">
                    <div class="header-acoordion">
                        <h1>Destacados</h1>
                        <!-- <span>+</span> -->
                     <label class="checkBox" for="destacado">
                            <input type="checkbox" id="destacado" [(ngModel)]="destacado" (change)="changeDestacado()"  [ngModelOptions]="{standalone: true}">
                            <span class="checkmarkBox" for="destacado"></span>
                        </label>
                    </div>
                </div>
                <div class="item">
                    <a class="header-acoordion" (click)="filtros.categoriaActivo = !filtros.categoriaActivo">
                        <h1>Categoria</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion">
                        <div class="lista-seleccionable" *ngIf="filtros.categoriaActivo">
                            <a (click)="filtros.categoria = ''" [ngClass]="filtros.categoria == '' ? 'activo' : ''">
                                <mat-icon>done</mat-icon>
                                Todos
                            </a>
                            <a (click)="filtros.categoria = row" [ngClass]="filtros.categoria == (row | lowercase)  ? 'activo' : ''" *ngFor="let row of categorias; let key = index">
                                <mat-icon>done</mat-icon>
                                {{row}}
                            </a>
                        </div>
                    </div>
                </div>
                <!-- <div class="item">
                    <a class="header-acoordion" (click)="filtros.materialActivo = !filtros.materialActivo">
                        <h1>Materiales</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion">
                        <div class="lista-seleccionable" *ngIf="filtros.materialActivo">
                            <a (click)="filtros.material = ''" [ngClass]="filtros.material == '' ? 'activo' : ''">
                                <mat-icon>done</mat-icon>
                                Todos
                            </a>
                            <a (click)="filtros.material = row" [ngClass]="filtros.material == row ? 'activo' : ''" *ngFor="let row of materiales; let key = index">
                                <mat-icon>done</mat-icon>
                                {{row}}
                            </a>
                        </div>
                    </div>
                </div> -->
                <div class="item">
                    <a class="header-acoordion" (click)="filtros.precioActivo = !filtros.precioActivo">
                        <h1>Precio</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion precioFiltros" *ngIf="filtros.precioActivo">
                        <label>
                            <span>Valor minimo: </span>
                            <div class="group">
                                <b>$</b>
                                <input type="number" [(ngModel)]="filtros.minValue">
                            </div>
                        </label>
                        <label>
                            <span>Valor maximo: </span>
                            <div class="group">
                                <b>$</b>
                                <input type="number" [(ngModel)]="filtros.maxValue">
                            </div>
                        </label>
                        <div class="sliderPrecio">
                            <ngx-slider [(value)]="filtros.minValue" [(highValue)]="filtros.maxValue" [options]="options"></ngx-slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="items">
            <div class="lista-items">
                <!-- <img src="../../../../assets/img/sin-conexion.png" alt=""> -->
                <div class="sin-encontrar" *ngIf="(filterItems(productos, filtros) | filter:buscarItems).length == 0">
                    <h2>No se ha encontrado los productos requeridos</h2>
                    <mat-icon>support_agent</mat-icon>
                    <a href="https://wa.me/{{dataSite.telefono_whatsapp}}" target="_blank">Comunicate con nostros por medio de nuestro whatsapp y te ayudaremos</a>
                </div>
                <a class="item" [@fadeInOut]
                    *ngFor="let rowProducto of filterItems(productos, filtros) | filter:buscarItems | paginate: { itemsPerPage: page.pageSize, currentPage: (page.pageIndex + 1) }; let indexProducto = index" 
                    [routerLink]="['/categoria', categoria.nombre_categoria_slug, rowProducto.categorias[0].id, 'producto', rowProducto.slug, rowProducto.codigo]">
                    <div class="img">
                        <img [src]="url+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'" alt="" src-fallback="{{ default }}">
                    </div>
                    <div class="info">
                        <div class="colores">
                            <span *ngFor="let rowColor of rowProducto.colores.slice(0, 5)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]></span>
                            <span class="mas" *ngIf="rowProducto.colores.length > 5" [@fadeInOut]>15+</span>
                        </div>
                        <p>{{rowProducto.nombre}}</p>
                        <p>Código: {{rowProducto.codigo}}</p>
                        <p class="precio">{{rowProducto.unidad_mayoreo}}: {{rowProducto.precio_neto * rowProducto.factor_web | currency}} MXN</p>
                        <!-- <p class="precio">{{rowProducto.unidad}}: {{rowProducto.precio_neto | currency}} MXN</p> -->
                        <!-- <p class="precio descuento">Pieza $17.50 MXN</p> -->
                    </div>
                </a>
            </div>
            <mat-paginator 
                (page)="page = $event"
                [length]="page.length"
                [pageSize]="page.pageSize"
                aria-label="Select page">
            </mat-paginator>
        </div>
    </div>
</div>