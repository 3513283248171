import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OnDestroy } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-recuperar',
	templateUrl: './recuperar.component.html',
	styleUrls: ['./recuperar.component.scss']
})
export class RecuperarComponent implements OnDestroy {
	repuesta: any = {};
	formularioRegistro = this._formBuilder.group({
		correo: [this.repuesta.correo, Validators.required]
	});
	loading: boolean = false;
	constructor(
		private _formBuilder: FormBuilder,
		private router: Router,
		private servicios: ApiService,
		private snackBar: MatSnackBar
	) { }

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	enviar() {
		this.loading = true;
		this.subs.push(this.servicios.recuperarCuenta(this.repuesta)
		.subscribe(
			response => {
				if(response.estatus == 20) {
					// setTimeout(() => {
					// 	this.router.navigate(['/cliente/mi-cuenta']);
					// }, 1000);
					this.error(response.mensaje);
					
				} else {
					this.error(response.mensaje, 'alert-snack-bar');
				}
				this.loading = false;
			},
			error => {
				this.error(error.mensaje, 'alert-snack-bar');
				//his.groupForm.reset();
				this.loading = false;
			}
		));
	}

	error(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
