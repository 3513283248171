import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BolsaTrabajoComponent } from './componentes/paginas/bolsa-trabajo/bolsa-trabajo.component';
import { CarritoComponent } from './componentes/paginas/carrito/carrito.component';
import { CategoriaComponent } from './componentes/paginas/categoria/categoria.component';
import { HistoriaComponent } from './componentes/paginas/historia/historia.component';
import { HomeComponent } from './componentes/paginas/home/home.component';
import { PasarelaDePagoComponent } from './componentes/paginas/pasarela-de-pago/pasarela-de-pago.component';
import { SucursalesComponent } from './componentes/paginas/sucursales/sucursales.component';
import { CuentaComponent } from './componentes/paginas/usuarios/cuenta/cuenta.component';
import { LoginComponent } from './componentes/paginas/usuarios/login/login.component';
import { PedidosComponent } from './componentes/paginas/usuarios/pedidos/pedidos.component';
import { RegistroComponent } from './componentes/paginas/usuarios/registro/registro.component';
import { RecuperarComponent } from './componentes/paginas/usuarios/recuperar/recuperar.component';
import { CambiarPasswordComponent } from './componentes/paginas/usuarios/cambiar-password/cambiar-password.component';
import { ConfirmacionComponent } from './componentes/paginas/confirmacion/confirmacion.component';
import { ProcesarPagoTarjetaComponent } from './componentes/paginas/procesar-pago-tarjeta/procesar-pago-tarjeta.component';
import { PerfilComponent } from './componentes/paginas/perfil/perfil.component';
import { ContactoComponent } from './componentes/paginas/contacto/contacto.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'categoria/busqueda-producto', component: CategoriaComponent },
  { path: 'categoria/:nombreCategoria/:idCategoria', component: CategoriaComponent },
  { path: 'categoria/:nombreCategoria/:idCategoria/producto/:nombreProducto/:codigo', component: PerfilComponent },
  { path: 'producto/:idCategoria/:codigo', component: PerfilComponent },
  { path: 'carrito', component: CarritoComponent },
  { path: 'pasarela-de-pago', component: PasarelaDePagoComponent },
  { path: 'pasarela-de-pago/procesar-pago-tarjeta', component: ProcesarPagoTarjetaComponent },
  { path: 'pasarela-de-pago/listo', component: ConfirmacionComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cliente/mi-cuenta', component: CuentaComponent },
  { path: 'cliente/mis-pedidos', component: PedidosComponent },
  { path: 'cliente/recuperar-password', component: RecuperarComponent },
  { path: 'cliente/recuperar-password/:token', component: CambiarPasswordComponent },
  { path: 'historia', component: HistoriaComponent },
  { path: 'sucursales', component: SucursalesComponent },
  { path: 'bolsa-trabajo', component: BolsaTrabajoComponent },
  { path: 'contacto', component: ContactoComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
