<div class="cont-registro">
    <div class="regitro">
        <h1>Cambiar contraseña:</h1>
        <form [formGroup]="formularioRegistro" (submit)="enviar()" class="formulario">
            <h4>Cambie su contraseña <small>(recuerde que esta será su nueva contraseña para entrar a la aplicación)</small>.</h4>
            <label for="">
                Contraseña
                <input type="password" formControlName="password" [(ngModel)]="repuesta.password">
            </label>
            <button type="submit" [disabled]="formularioRegistro.invalid" class="button">Enviar para recuperar</button>

            <a routerLink="/login">Regresar</a>
        </form>
    </div>
</div>