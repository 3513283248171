import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { environment } from 'src/environments/environment';
import { Fancybox } from '@fancyapps/ui';
import { SessionService } from 'src/app/core/servicios/session.service';

@Component({
	selector: 'app-sucursales',
	templateUrl: './sucursales.component.html',
	styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit, OnDestroy {
	default = environment.APIEndpoint + '/assets/img/default.png';
	sucursales: any = [];
	bannersTienda: any = [];
	bannersPromocion: any = [];
	url = environment.APIEndpoint;
	slides = [];
	
	slideConfig = {
		dots: false,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
	};

	// slideConfigVertical = {
	// 	slidesToShow: 3,
	// 	slidesToScroll: 1,
	// 	//autoplay: true,
	// 	vertical: true,
	// 	verticalSwiping: true,
	// 	autoplaySpeed: 2000,
	// 	prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
	// 	nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
	// 	responsive: [
	// 		{
	// 			breakpoint: 480,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1,
	// 				vertical: false,
	// 				verticalSwiping: false,
	// 			}
	// 		}
	// 	]
	// };

	slideConfigPromociones = {
		slidesToShow: 3,
		slidesToScroll: 1,
		//autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 1030,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	//sucursales: any = [];
	respuesta: any = {};
	formContacto: any = this._formBuilder.group({
		recaptchaReactive: new FormControl(this.respuesta.recaptchaReactive, Validators.required),
		nombre: new FormControl(this.respuesta.nombre, Validators.required),
		telefono: new FormControl(this.respuesta.telefono, Validators.required),
		correo: new FormControl(this.respuesta.correo, Validators.required),
		mensaje: new FormControl(this.respuesta.mensaje, Validators.required),
	});

	constructor(
		private _formBuilder: FormBuilder,
		private servicios: ApiService,
		private snackBar: MatSnackBar,
		private elRef: ElementRef,
		private serviciosSite: SessionService
	) {
	}
	dataSite: any = {};
	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
		Fancybox.unbind(this.elRef.nativeElement);
		Fancybox.close();
	}

	ngOnInit() {
		//this.getSucursales();
		this.subs.push(this.serviciosSite.datosSite.subscribe(data => {
			console.log(data);
			this.dataSite = data;
		}));
		this.getDatos();
		Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
			// Custom options
		});
	}

	getDatos() {
		this.subs.push(this.servicios.getSucursales().subscribe(
			response => {
				this.sucursales = response.return;
			},
			error => {
				console.log(error);
			}
		));
		this.subs.push(this.servicios.getBannersTienda()
			.subscribe(
				response => {
					this.bannersTienda = response.return;
				},
				error => {
					console.log(error);
				}
		));
		this.subs.push(this.servicios.getBannersPromociones()
			.subscribe(
				response => {
					this.bannersPromocion = response.return;
				},
				error => {
					console.log(error);
				}
		));
	}

	// afterChange(e: any) {
	// 	const elemsInit: NodeListOf<HTMLElement> = e.event.target.querySelectorAll('.button-sucursal');
	// 	let elements: HTMLElement[] = [];
	// 	for (let k in elemsInit) {
	// 		elements.push(elemsInit[k]);
	// 	}
	// 	elements = elements.filter(elem => typeof (elem) === 'object');
	// 	for (const element of elements) {
	// 		//element.addEventListener('click', this.seleccionSucursal.bind(this));
	// 		// function(this) {
	// 		// 	console.log(this);
	// 		// 	//(this.target as HTMLInputElement).data('data-key');
	// 		// 	const dataKey: HTMLElement = this;

	// 		// 	this.seleccionSucursal.bind(dataKey.getAttribute('data-key'))
	// 		// }
	// 	}
	// }

	enviar() {
		this.servicios.enviarCorreo(this.respuesta).subscribe(
			response => {
				if (response.estatus == 20) {
					this.mensaje(response.mensaje);
					this.respuesta = {};
				} else {
					this.mensaje(response.mensaje, 'alert-snack-bar');
				}
			},
			error => {
				this.mensaje('Por favor vuelve a intentar mandar tu correo', 'alert-snack-bar');
			}
		);
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
