import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-modal-eliminar',
	templateUrl: './modal-eliminar.component.html',
	styleUrls: ['./modal-eliminar.component.scss']
})
export class ModalEliminarComponent {
	repuesta: any = {};
	constructor(
		public dialogRef: MatDialogRef<ModalEliminarComponent>,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
		private servicios: ApiService,
		private snackBar: MatSnackBar
	) { }

	closeDialog() {
		this.dialogRef.close(false);
	}

	enviar() {
		this.servicios.eliminarDireccion(this.dataDialog.id, this.dataDialog.usuario)
		.subscribe(
			response => {
				this.mensaje('Eliminado con exito');
				this.dialogRef.close(true);
			},
			error => {
				this.mensaje('No se pudo eliminar con exito', 'alert-snack-bar');
			}
		);
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
