<div class="cont-historia">
    <div class="cont-contacto">
        <div class="contacto">
            <h1>Contáctanos</h1>
            <form [formGroup]="formContacto" (submit)="enviar()">
                <label for="">
                    <input type="text" placeholder="Nombre" formControlName="nombre" [(ngModel)]="respuesta.nombre">
                </label>
                <label for="">
                    <input type="text" placeholder="Teléfono" formControlName="telefono" [(ngModel)]="respuesta.telefono">
                </label>
                <label for="">
                    <input type="text" placeholder="Email" formControlName="correo" [(ngModel)]="respuesta.correo">
                </label>
                <label for="">
                    <textarea placeholder="Mensaje" formControlName="mensaje" [(ngModel)]="respuesta.mensaje"></textarea>
                </label>
                <re-captcha formControlName="recaptchaReactive" [(ngModel)]="respuesta.recaptchaReactive"></re-captcha>
                <button type="submit" [disabled]="formContacto.invalid" class="button">Enviar mensaje</button>
            </form>
        </div>
        <div class="mapa">
            <h1>Encuentra nuestras oficinas</h1>
            <a href="">
                <img src="../../../../assets/img/mapa.jpg" alt="">
            </a>
        </div>
    </div>
</div>
