import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';
import { ModalAgregarDireccionComponent } from '../../modales/modal-agregar-direccion/modal-agregar-direccion.component';
import { ModalCambiarPasswordComponent } from '../../modales/modal-cambiar-password/modal-cambiar-password.component';
import { ModalEditarCuentaComponent } from '../../modales/modal-editar-cuenta/modal-editar-cuenta.component';
import { ModalEliminarComponent } from '../../modales/modal-eliminar/modal-eliminar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';

@Component({
	selector: 'app-cuenta',
	templateUrl: './cuenta.component.html',
	styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit, OnDestroy {
	page: any = {
		length: 0,
		pageIndex: 0,
		pageSize: 2,
		previousPageIndex: 0
	};

	direcciones: any[] = [];
	idUsuario: any = {};
	infoCliente: any = {};
	facturacion: any = {};
	colonias: any[] = [];
	loading: boolean = false;
	facturacionFormulario = this._formBuilder.group({
		direccion_facturacion: [this.facturacion.direccion_facturacion, Validators.required],
		n_ext_direccion_facturacion: [this.facturacion.n_ext_direccion_facturacion],
		n_int_direccion_facturacion: [this.facturacion.n_int_direccion_facturacion],
		codigo_postal_direccion_facturacion: [this.facturacion.codigo_postal_direccion_facturacion, Validators.required],
		colonia_direccion_facturacion: [this.facturacion.colonia_direccion_facturacion, Validators.required],
		ciudad_direccion_facturacion: [this.facturacion.ciudad_direccion_facturacion, Validators.required],
		estado_direccion_facturacion: [this.facturacion.estado_direccion_facturacion, Validators.required],
	});

	constructor(
		private router: Router,
		private _formBuilder: FormBuilder,
		private servicios: ApiService,
		private sessionServices: SessionService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.subs.push(this.sessionServices.sessionObservable.subscribe(cliente => {
			this.idUsuario = cliente.id_cliente;
			this.getDirecciones();
			this.getUsuario();
			//this.openModalEliminarDireccion(1);
		}));
		this.subs.push(this.sessionServices.sessionObservable.subscribe(items => {
			if(Object.entries(items).length === 0) {
				this.router.navigate(['/login']);
			}
		}));
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}
	
	openModalEditar() {
		const dialogRef = this.dialog.open(
			ModalEditarCuentaComponent,
			{
				data: {
					info: this.infoCliente,
					usuario: this.idUsuario
				}
			}
		);
	}

	openModalCambiarPassword() {
		const dialogRef = this.dialog.open(
			ModalCambiarPasswordComponent,
			{
				data: {
					usuario: this.idUsuario
				}
			}
		);
	}

	openModalDireccion(data: any = null, tipo: number = 1, id: number = 0) {
		const dialogRef = this.dialog.open(
			ModalAgregarDireccionComponent,
			{
				data: {
					direccion: data,
					tipo: tipo,
					usuario: this.idUsuario,
					id: id
				}
			}
		);
		this.subs.push(dialogRef.afterClosed().subscribe(result => {
			this.getDireccionEnvio();
		}));
	}

	getDirecciones() {
		this.getDireccionEnvio();
		this.getDireccionFacturacion();
	}

	getDireccionEnvio() {
		this.loading = true;
		this.subs.push(this.servicios.getDireccionesEnvio(this.idUsuario)
			.subscribe(
			response => {
				this.direcciones = response.return;
				this.page.length = this.direcciones.length;
				this.loading = false;
			},
			error => {
				this.loading = false;
			}));
	}

	getDireccionFacturacion() {
		this.subs.push(this.servicios.getDireccionFacturacion(this.idUsuario)
		.subscribe(
		response => {
			this.facturacion = response.return;
			this.getCodigoPosta(this.facturacion.codigo_postal_direccion_facturacion);
		},
		error => {
			
		}));
	}

	getCodigoPosta(cp: number) {
		this.subs.push(this.servicios.getCodigoPostal(cp)
		.subscribe(
		response => {
			console.log(response);
			this.facturacion.ciudad_direccion_facturacion = response[0].municipio;
			this.facturacion.estado_direccion_facturacion = response[0].estado;
            this.colonias = response;
		},
		error => {
			
		}));
	}

	getUsuario() {
		this.subs.push(this.servicios.getUsuario(this.idUsuario)
		.subscribe(
			response => {
				this.infoCliente = response.return;
			},
			error => {
				
			}));
	}

	defaultDireccion(id: number) {
		this.subs.push(this.servicios.defaultDireccion(id)
		.subscribe(
		response => {
			this.getDirecciones();
		},
		error => {
			
		}));
	}

	openModalEliminarDireccion(id: number) {
		const dialogRef = this.dialog.open(
			ModalEliminarComponent, {
				data: {
					id: id,
					usuario: this.idUsuario
				}
			}
		);
		this.subs.push(dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this.getDireccionEnvio();
			}
		}));
	}

	enviarDatosFacturacion() {
		this.subs.push(this.servicios.editarDireccionFacturacion(this.idUsuario, this.facturacion.id_direccion_facturacion, this.facturacion)
		.subscribe(
			response => {
				this.mensaje(response.mensaje);
			},
			error => {
				this.mensaje('No se pudo actualizados con exito', 'alert-snack-bar');
			}
		));
	}

	salir() {
		this.subs.push(this.sessionServices.logout().subscribe(cliente => {
			setTimeout(() => {
				this.router.navigate(['/login']);
			}, 1000);
		}));
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
