<header [ngClass]="(shrinkHeader) ? 'scroll' : ''">
    <a class="envio" (click)="openModalPoliticas()">
        <a>Conoce los detalles de envio</a>
        <a>
            Envios a toda la república
            <img src="../../../assets/img/iconos/envio.svg" alt="">
        </a>
        <a></a>
         <!--<a routerLink="/historia">Sucursales</a> -->
    </a>
    <div class="header">
        <a routerLink="/" id="logo">
            <img src="../../../assets/img/logo.png" alt="">
        </a>
        <a (click)="celular = !celular" class="btn-menu">
            <mat-icon>menu</mat-icon>
        </a>
        <!-- <a routerLink="/" id="logo">
            <img src="../../../assets/img/logo.png" alt="">
        </a> -->
        <ul class="menu" [ngClass]="(celular == true) ? 'celular' : ''">
            <li>
                <a routerLink="/" (click)="celular = false">
                    <span>Inicio</span>
                </a>

            </li>
            <li *ngFor="let row of categorias; let key = index" class="menuPrincipal">
                <a (click)="celular = false" [routerLink]="['/categoria', row.nombre_categoria_slug, row.id_categoria]">
                    <span>{{row.nombre_categoria | lowercase}}</span>
                </a>
                <div class="submenu" *ngIf="row.subcategorias">
                    <a [routerLink]="['/categoria', row.nombre_categoria_slug, row.id_categoria]" [queryParams]="{subcategoria: rowSub | lowercase}" *ngFor="let rowSub of row.subcategorias">{{rowSub}}</a>
                </div>
            </li>
            <li>
                <a routerLink="/sucursales" (click)="celular = false">
                    <span>Mis tiendas</span>
                </a>
            </li>
        </ul>
        <!--<a routerLink="/" id="logo">
            <img src="../../../assets/img/logo.png" alt="">
        </a>-->
        <ul class="opciones">
            <li>
                <a (click)="buscarProducto()">
                    <img src="../../../assets/img/iconos/search.svg" alt="">
                </a>
            </li>
            <li>
                <a routerLink="/cliente/mi-cuenta" *ngIf="sessionUser == true">
                    <img src="../../../assets/img/iconos/cuenta.svg" alt="">
                </a>
                <a routerLink="/login" *ngIf="sessionUser == false">
                    <img src="../../../assets/img/iconos/cuenta.svg" alt="">
                </a>
            </li>
            <li>
                <a routerLink="/carrito" class="carrito">
                    <img src="../../../assets/img/iconos/carrito.svg" alt="">
                    <span>{{itemsCarrito}}</span>
                </a>
            </li>
        </ul>
        <div class="buscar" *ngIf="activarBuscar">
            <form (submit)="enviar()" class="formulario">
                <label for="">
                    <input class="input-group-field" id="buscarInput" #myNameElem [ngModelOptions]="{standalone: true}" [(ngModel)]="buscar" type="search" placeholder="Buscar un articulo:">
                </label>
            </form>
        </div>
    </div>
</header>
