<div class="cont-registro">
    <div class="regitro">
        <h1>Bolsa de trabajo:</h1>
        <form [formGroup]="formContacto" (submit)="enviar()">
            <h4>Datos del usuario</h4>
            <div class="inputs">
                <label for="">
                    Nombre
                    <input type="text" formControlName="nombre" [(ngModel)]="respuesta.nombre">
                </label>
                <label for="">
                    Teléfono
                    <input type="text" formControlName="telefono" [(ngModel)]="respuesta.telefono">
                </label>
                <label for="">
                    Email
                    <input type="text" formControlName="correo" [(ngModel)]="respuesta.correo">
                </label>
                <label for="">
                    Sexo
                    <select formControlName="sexo" [(ngModel)]="respuesta.sexo">
                        <option value="Hombre">Hombre</option>
                        <option value="Mujer">Mujer</option>
                    </select>
                </label>
                <label for="">
                    Estado
                    <select formControlName="estado" [(ngModel)]="respuesta.estado" (change)="getMunicipios()">
                        <option [value]="row.idEstado" *ngFor="let row of estados">{{row.estado}}</option>
                    </select>
                </label>
                <label for="">
                    Municipio
                    <select formControlName="municipio" [(ngModel)]="respuesta.municipio">
                        <option value="{{row.idMunicipio}}" *ngFor="let row of municipios">{{row.municipio}}</option>
                    </select>
                </label>
                <label for="">
                    Ultimo empleo
                    <input type="text" formControlName="ultimo_empleo" [(ngModel)]="respuesta.ultimo_empleo">
                </label>
                <label for="">
                    Puesto de interés
                    <select formControlName="puesto" [(ngModel)]="respuesta.puesto">
                        <option value="">Puesto de interés</option>
                        <option value="Vendedor de piso">Vendedor de piso</option>
                        <option value="Cajero">Cajero</option>
                        <option value="Auxiliar de bodega">Auxiliar de bodega</option>
                        <option value="Seguridad">Seguridad</option>
                        <option value="Chofer - instalador">Chofer - instalador</option>
                        <option value="Gestor de cobranza">Gestor de cobranza</option>
                        <option value="Auxiliar de costura">Auxiliar de costura</option>
                        <option value="Asesor teléfonico">Asesor teléfonico</option>
                        <option value="Agente de ventas">Agente de ventas</option>
                        <option value="Otro">Otro</option>
                    </select>
                </label>
                <label for="">
                    Espectativas económicas
                    <input type="text" formControlName="espectativas_economicas" [(ngModel)]="respuesta.espectativas_economicas">
                </label>
            </div>
            <re-captcha formControlName="recaptchaReactive" [(ngModel)]="respuesta.recaptchaReactive"></re-captcha>
            <button type="submit" [disabled]="formContacto.invalid" class="button">Enviar mi información</button>
        </form>
    </div>
</div>