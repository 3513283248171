import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-modal-aviso-privacidad',
	templateUrl: './modal-aviso-privacidad.component.html',
	styleUrls: ['./modal-aviso-privacidad.component.scss']
})
export class ModalAvisoPrivacidadComponent {
	repuesta: any = {};
	constructor(
		public dialogRef: MatDialogRef<ModalAvisoPrivacidadComponent>,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
	) {
		this.repuesta.texto = dataDialog.info;
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
