<div class="cont-registro">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="regitro">
        <h1>Recuperar contraseña:</h1>
        <form [formGroup]="formularioRegistro" (submit)="enviar()" class="formulario">
            <h4>Verifique su correo por favor.</h4>
            <div class="inputs">
                <label for="">
                    Correo
                    <input type="text" formControlName="correo" [(ngModel)]="repuesta.correo">
                </label>
            </div>
            <button type="submit" [disabled]="formularioRegistro.invalid" class="button">Enviar para recuperar</button>

            <a routerLink="/login">Regresar</a>
        </form>
    </div>
</div>