import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-modal-agregar-direccion',
	templateUrl: './modal-agregar-direccion.component.html',
	styleUrls: ['./modal-agregar-direccion.component.scss']
})
export class ModalAgregarDireccionComponent {
	repuesta: any = {};
	colonias: any = [];
	modalFormulario = this._formBuilder.group({
		nombre_direccion_envio: [this.repuesta.nombre_direccion_envio, Validators.required],
		direccion_envio: [this.repuesta.direccion_envio, Validators.required],
		n_ext_direccion_envio: [this.repuesta.n_ext_direccion_envio, Validators.required],
		n_int_direccion_envio: [this.repuesta.n_int_direccion_envio],
		codigo_postal_direccion_envio: [this.repuesta.codigo_postal_direccion_envio, Validators.required],
		ciudad_direccion_envio: [this.repuesta.ciudad_direccion_envio, Validators.required],
		colonia_direccion_envio: [this.repuesta.colonia_direccion_envio, Validators.required],
		estado_direccion_envio: [this.repuesta.estado_direccion_envio, Validators.required],
		codigo_estado: [this.repuesta.codigo_estado],
		codigo_municipio: [this.repuesta.codigo_municipio],
	});

	constructor(
		private _formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ModalAgregarDireccionComponent>,
		private apiServicio: ApiService,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
	) { }

	ngOnInit() {
		if(this.dataDialog.direccion) {
			this.repuesta = this.dataDialog.direccion;
			this.getCodigoPosta(this.repuesta.codigo_postal_direccion_envio);
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	enviar() {
		if(this.dataDialog.tipo == 1) {
			this.apiServicio.agregarDireccion(this.dataDialog.usuario, this.repuesta)
			.subscribe(
				response => {
					this.dialogRef.close();
				},
				error => {
					
				});
		} else {
			this.apiServicio.editarDireccion(this.dataDialog.usuario, this.repuesta, this.dataDialog.id)
			.subscribe(
				response => {
					this.dialogRef.close();
				},
				error => {
					
				});
		}
	}

	getCodigoPosta(cp: number) {
		this.apiServicio.getCodigoPostal(cp)
		.subscribe(
		response => {
			console.log(response);
			this.repuesta.ciudad_direccion_envio = response[0].municipio;
			this.repuesta.estado_direccion_envio = response[0].estado;
            this.colonias = response;
		},
		error => {
			
		});
	}
}
