<div class="modal">
    <a (click)="closeDialog()" class="close"><mat-icon>close</mat-icon></a>
    <h1>Modificar información del cliente</h1>
    <form [formGroup]="modalFormulario" (submit)="enviar()" class="formulario">
        <label for="">
            <span>Nombre *</span>
            <input type="text" formControlName="nombre_cliente" [(ngModel)]="repuesta.nombre_cliente">
        </label>
        <label for="">
            <span>Número teléfonico *</span>
            <input type="text" formControlName="celular_cliente" [(ngModel)]="repuesta.celular_cliente">
        </label>
        <div class="grupo">
            <button type="submit" [disabled]="modalFormulario.invalid" class="button">Guardar</button>
        </div>
    </form>
</div>