import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('500ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('500ms', style({ opacity: 0 }))
			])
		])
	]
})
export class HomeComponent implements OnInit, OnDestroy {
	categorias: any = [];
	banners: any = [];
	url = environment.APIEndpoint;
	urlImg = environment.APIEndpoint + '/assets/productos';
	default = environment.APIEndpoint + '/assets/img/default.png';
	loNuevo: any = [];
	promociones: any = [];
	slideConfig = {
		dots: true,
		infinite: true,
		arrow: false,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
  		autoplaySpeed: 2000,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					dots: false
				}
			}
		]
	};
	
	bannersPromocion: any = [];

	constructor(
		private servicios: ApiService,
	) { }

	ngOnInit() {
		this.getHome();
		this.getCategoriaProductos();
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	getHome() {
		this.subs.push(this.servicios.getCategoriasBanner()
			.subscribe(
				response => {
					this.categorias = response.return;
					console.log(this.categorias);
				},
				error => {
					console.log(error);
				}
			));
		this.subs.push(this.servicios.getBanners()
			.subscribe(
				response => {
					this.banners = response.return.banner;
					this.bannersPromocion = response.return.bannerPromocion;
					console.log(this.bannersPromocion);
				},
				error => {
					console.log(error);
				}
			));
	}

	getCategoriaProductos() {
		this.subs.push(this.servicios.getProductosDescuento()
		.subscribe(
			response => {
				this.promociones = response.productos.filter((p: any) => p.promocion.toLowerCase() == 'si').sort(function () {
					return Math.random() - 0.8;
				  });
			},
			error => {
				console.log(error);
			}
		));

		this.subs.push(this.servicios.getProductosDestacado()
		.subscribe(
			response => {
				this.loNuevo = response.productos.filter((p: any) => p.articulo_destacado.toLowerCase() == 'si').sort(function () {
					return Math.random() - 0.5;
				});
			},
			error => {
				console.log(error);
			}
		));
	}


}
