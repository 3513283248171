<form (ngSubmit)="enviarPago()" [formGroup]="formPago" id="payment-form">
<input type="hidden" name="token_id" id="token_id">
<div class="pasarela-pago">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="informacion-pago">
        <div class="envio">
            <div class="opciones">
                <h1 class="titulo"><span>1</span> Envío</h1>
                <a [routerLink]="['/cliente/mi-cuenta']">Agregar nueva dirección</a>
            </div>
            <label for="" class="full">
                <input type="text" placeholder="Nombre de la dirección *" [(ngModel)]="direccion.nombre_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Dirección *" [(ngModel)]="direccion.direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Número exterior" [(ngModel)]="direccion.n_ext_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Número interior" [(ngModel)]="direccion.n_int_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Código postal *" [(ngModel)]="direccion.codigo_postal_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Ciudad *" [(ngModel)]="direccion.ciudad_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Colonia *" [(ngModel)]="direccion.colonia_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <label for="">
                <input type="text" placeholder="Estado *" [(ngModel)]="direccion.estado_direccion_envio" [ngModelOptions]="{standalone: true}">
            </label>
            <small>Dirección seleccionada por default, para seleccionar otra direccion entra <a [routerLink]="['/cliente/mi-cuenta']">aquí</a></small>
        </div>
        <div class="gasto-envio">
            <table cellspacing="0">
                <thead>
                    <tr>
                        <th>Método de entrega</th>
                        <th>Llegada estimada</th>
                        <th>Gastos de envío</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Estandar</td>
                        <td>3 a 5 días hábiles</td>
                        <td>{{envioEstafeta.monto | currency}} MXN</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="terminar-pago ensucursal" *ngIf="totales.total >= limitePago">
            <p>Para montos mayores o igual a {{limitePago | currency}}, puede comunicarte con nosotros para dar un servicio personalizado.</p>
            <a class="button">Servicio personalizado</a>
            <p>El total de este pedido está totalmente garantizado. No tendrá que abonar cargos adicionales al recibir su pedido.</p>
        </div>
        <div class="pago" *ngIf="totales.total < limitePago">
            <h1 class="titulo"><span>2</span> Pago</h1>
            <div class="items-pago">
                <div class="item">
                    <label>
                        <div class="radiobutton">
                            <input type="radio" name="tipo_pago" [value]="6" formControlName="tipo_pago" [(ngModel)]="dataPago.tipo_pago">
                            <span class="checkmark"></span>
                        </div>
                        Tarjeta de débito y crédito - 3D Secure
                        <img src="../../../../assets/img/pago/comisiones_tarjetas.png" alt="" id="tarjetas">
                    </label>
                    <div class="informacion" [ngClass]="(dataPago.tipo_pago != 6) ? 'hidden' : ''">
                        <p class="lock"><mat-icon>lock</mat-icon>La conexión por la cual se transfieren los datos es segura</p>
                        <label>
                            Nombre del tarjetahabiente
                            <input type="text" placeholder="Como aparece en la tarjeta" formControlName="nombre_tarjeta" [(ngModel)]="dataPago.nombre_tarjeta" [required]="(dataPago.tipo_pago == 6) ? true : false" autocomplete="off" data-openpay-card="holder_name">
                        </label>
                        <label for="card_number">
                            Número de la tarjeta
                            <input type="text" onKeyPress="if(this.value.length==16) return false;" autocomplete="off" id="card_number" formControlName="num_t" [(ngModel)]="dataPago.num_t" [required]="(dataPago.tipo_pago == 6) ? true : false" placeholder="•••• •••• •••• ••••">
                            <input type="hidden" data-openpay-card="card_number" id="card_number_openpay">
                        </label>
                        <label for="card_expiry_date" class="date">
                            Válida hasta:
                            <div class="date">
                                <input type="number" onKeyPress="if(this.value.length==2) return false;" pattern="\d*" [required]="(dataPago.tipo_pago == 6) ? true : false" formControlName="expm" [(ngModel)]="dataPago.expm" placeholder="Mes" data-openpay-card="expiration_month" size="2" id="card_expiry_month">
                                <input type="number" onKeyPress="if(this.value.length==4) return false;" [required]="(dataPago.tipo_pago == 6) ? true : false" formControlName="expy" [(ngModel)]="dataPago.expy" placeholder="Año" data-openpay-card="expiration_year" size="4" id="card_expiry_year">
                            </div>
                        </label>
                        <label for="card_verification">
                            CVC:
                            <input type="password" onKeyPress="if(this.value.length==4) return false;" autocomplete="off" [required]="(dataPago.tipo_pago == 6) ? true : false" data-openpay-card="cvv2" id="card_verification" placeholder="••••" formControlName="xx" [(ngModel)]="dataPago.xx">
                            <input type="hidden" name="token_id" id="token_id">
                        </label>
                    </div>
                </div>
                <!-- <div class="item">
                    <label>
                        <div class="radiobutton">
                            <input type="radio" name="tipo_pago" id="pago-2" [value]="7" formControlName="tipo_pago" [(ngModel)]="dataPago.tipo_pago">
                            <span class="checkmark"></span>
                        </div>
                        Pago en efectivo y PayNet
                        <img src="../../../../assets/img/pago/logo_paynet1.png" alt="" id="paynet">
                    </label>
                </div>
                <div class="item">
                    <label>
                        <div class="radiobutton">
                            <input type="radio" name="tipo_pago" id="pago-3" [value]="8" formControlName="tipo_pago" [(ngModel)]="dataPago.tipo_pago">
                            <span class="checkmark"></span>
                        </div>
                        Tranferencia interbancaria
                        <img src="../../../../assets/img/pago/transferencia_comision_spei.png" alt="" id="tranferencia">
                    </label>
                </div> -->
            </div>
        </div>
        <div class="terminar-pago" *ngIf="totales.total < limitePago">
            <label>
                <div class="checkbox">
                    <input type="checkbox" checked="checked" [ngModelOptions]="{standalone: true}" [(ngModel)]="enviarOfertas">
                    <span class="checkmark-box"></span>
                </div>
                <p>Recibir ofertas electrónicas o por correo electrónico de Assis. Puede anular la suscripción en cualquier momento.</p>
            </label>
            <re-captcha formControlName="recaptchaReactive" [(ngModel)]="dataPago.recaptchaReactive"></re-captcha>
            <button class="button" type="submit" [disabled]="formPago.invalid">Realizar pedido</button>
            <p>El total de este pedido está totalmente garantizado. No tendrá que abonar cargos adicionales al recibir su pedido.</p>
        </div>
    </div>
    <div class="informacion-carrito">
        <div class="totales">
            <div class="item" *ngIf="totales.subtotal > 0">
                <p>Subtotal</p>
                <p>{{totales.subtotal | currency}} MXN</p>
            </div>
            <div class="item descuento" *ngIf="totales.descuento > 0">
                <p>Ahorraste</p>
                <p>{{totales.descuento | currency}} MXN</p>
            </div>
            <div class="item" *ngIf="totales.envio > 0">
                <p>Envío</p>
                <p>{{totales.envio | currency}} MXN</p>
            </div>
            <div class="item total" *ngIf="totales.total > 0">
                <p><b>Total</b></p>
                <p><b>{{totales.total | currency}} MXN</b></p>
            </div>
        </div>
        <div class="scroll-productos-vertical">
            <ngx-slick-carousel class="items" #slickModal="slick-carousel" [config]="slideConfigVertical">
                <div ngxSlickItem class="item" *ngFor="let item of (carrito$ | async); let key = index">
                    <div class="img">
                        <img [src]="item.opciones.img_producto" alt="" src-fallback="{{ default }}">
                    </div>
                    <div class="texto">
                        <div class="info">
                            <h4>{{item.name}}</h4>
                            <p class="descripcion">{{item.opciones.nombre_producto}}</p>
                            <p class="precio">{{item.total | currency}} MXN</p>
                        </div>
                        <div class="opciones">
                            <!-- <p>Tamaño: Grande</p> -->
                            <p>Categoría: {{item.opciones.nombre_categoria}}</p>
                            <p>Web ID: {{item.opciones.codigo_producto}}</p>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
</form>