import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, filter, scan, take, switchMap, find } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class CarritoService {

	constructor() { }

	private carritoObservablePrivete: BehaviorSubject<any> =
		new BehaviorSubject<any>([]);

	ngOnInit() {
	}

	dataSave(data: any) {
      	window.localStorage.setItem('carrito', JSON.stringify(data));
	}

	getDataSave() {
		var str: any = window.localStorage.getItem('carrito');
		if(str) {
			var items = JSON.parse(str);
			this.carritoObservablePrivete.next(items);
		} else {
			this.carritoObservablePrivete.next([]);
		}
		return items;
	}

	get carritoObservable() {
		return this.carritoObservablePrivete.asObservable();
	}

	set carritoObservableData(data: any) {
		this.carritoObservablePrivete.next(data);
	}

	addItem(producto: any, opciones: any) {
		producto.opciones = opciones;
		let productos = this.carritoObservablePrivete.getValue();
		let encontro = false;
		let cantidad = 0;
		console.log(productos);
		if(productos.length > 0) {
			productos.forEach((element: any, key: number) => {
				if(element.id == producto.id) {
					encontro = true;
					cantidad = element.qty + producto.qty;
					if(producto.qty >= opciones.cantidad_disponible_factorweb && cantidad >= opciones.cantidad_disponible_factorweb) {
						cantidad = opciones.cantidad_disponible_factorweb;
					}
					productos[key].qty = cantidad;
					productos[key].total = productos[key].price * productos[key].qty;
				}
			});
		}
		if(encontro == false) {
			producto.total = producto.price * producto.qty;
			productos.push(producto);
		}
		this.carritoObservablePrivete.next([...productos]);
		return this.carritoObservablePrivete.asObservable();
	}

	deleteItem(key: number) {
		let productos = this.carritoObservablePrivete.getValue();
		if(Number.isInteger(key)) {
			productos.splice(key, 1);
		}
		this.carritoObservablePrivete.next([...productos]);
		return this.carritoObservablePrivete.asObservable();
	}

	editItem(key:number, cantidad: number) {
		let productos = this.carritoObservablePrivete.getValue();
		if(productos[key]) {
			if(productos[key].qty >= productos[key].opciones.cantidad_disponible_factorweb && cantidad >= productos[key].opciones.cantidad_disponible_factorweb) {
				cantidad = productos[key].opciones.cantidad_disponible_factorweb;
			}
			productos[key].qty = cantidad;
			productos[key].total = productos[key].price * productos[key].qty;
			this.carritoObservablePrivete.next([...productos]);
		}
		return this.carritoObservablePrivete.asObservable();
	}

	emptyCarrito() {
		this.carritoObservablePrivete.next([]);
		return this.carritoObservablePrivete.asObservable();
	}
}
