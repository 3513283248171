import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-bolsa-trabajo',
	templateUrl: './bolsa-trabajo.component.html',
	styleUrls: ['./bolsa-trabajo.component.scss']
})
export class BolsaTrabajoComponent {
	estados: any = [];
	municipios: any = [];
	respuesta: any = {};
	formContacto: any = this._formBuilder.group({
		recaptchaReactive: new FormControl(this.respuesta.recaptchaReactive, Validators.required),
		nombre: new FormControl(this.respuesta.nombre, Validators.required),
		telefono: new FormControl(this.respuesta.telefono, Validators.required),
		correo: new FormControl(this.respuesta.correo, Validators.required),
		estado: new FormControl(this.respuesta.estado, Validators.required),
		municipio: new FormControl(this.respuesta.municipio, Validators.required),
		puesto: new FormControl(this.respuesta.puesto, Validators.required),
		sexo: new FormControl(this.respuesta.sexo, Validators.required),
		ultimo_empleo: new FormControl(this.respuesta.ultimo_empleo),
		espectativas_economicas: new FormControl(this.respuesta.espectativas_economicas),
	});
	constructor(
		private _formBuilder: FormBuilder,
		private services: ApiService,
		private snackBar: MatSnackBar
	) {
	}

	ngOnInit() {
		this.getEstados();
	}

	getEstados() {
		this.services.getEstados().subscribe(
			response => {
				this.estados = response.data;
			},
			error => {
			}
		);
	}

	getMunicipios() {
		console.log(this.respuesta.estado);
		this.services.getMunicipios(this.respuesta.estado).subscribe(
			response => {
				this.municipios = response.data;
			},
			error => {
			}
		);
	}

	enviar() {
		this.services.enviarCorreo(this.respuesta).subscribe(
			response => {
				if (response.estatus == 20) {
					this.mensaje(response.mensaje);
					this.respuesta = {};
				} else {
					this.mensaje(response.mensaje, 'alert-snack-bar');
				}
			},
			error => {
				this.mensaje('Por favor vuelve a intentar mandar tu correo', 'alert-snack-bar');
			}
		);
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}

}
