import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OnDestroy } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';

@Component({
	selector: 'app-registro',
	templateUrl: './registro.component.html',
	styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnDestroy {
	repuesta: any = {};
	loading: boolean = false;
	formularioRegistro = this._formBuilder.group({
		nombre: [this.repuesta.nombre, Validators.required],
		apellido: [this.repuesta.apellido, Validators.required],
		telefono: [this.repuesta.telefono, Validators.required],
		correo: [this.repuesta.correo, Validators.required],
		password: [this.repuesta.password, Validators.required],
		nombre_direccion_facturacion: [this.repuesta.nombre_direccion_facturacion, Validators.required],
		rfc_direccion_facturacion: [this.repuesta.rfc_direccion_facturacion, Validators.required],
		codigo_postal_direccion_facturacion: [this.repuesta.codigo_postal_direccion_facturacion, Validators.required],
		recaptchaReactive: ['', Validators.required]
	});

	constructor(
		private _formBuilder: FormBuilder,
		private router: Router,
		private servicios: ApiService,
		private sessionServices: SessionService,
		private snackBar: MatSnackBar
	) { }

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	enviar() {
		this.loading = true;
		this.repuesta.recaptchaReactive = this.formularioRegistro.value.recaptchaReactive;
		this.subs.push(this.servicios.registrarCliente(this.repuesta)
			.subscribe(
				response => {
					if(response.estatus == 20) {
						this.sessionServices.sessionObservableData = response.return;
						setTimeout(() => {
							this.loading = false;
							this.router.navigate(['/cliente/mi-cuenta']);
						}, 1000);
					} else {
						this.loading = false;
						this.error(response.mensaje, 'alert-snack-bar');
					}
				},
				error => {
					this.loading = false;
					this.error(error.mensaje);
					//his.groupForm.reset();
				}
		));
	}

	error(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
