<div class="cont-registro">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="regitro">
        <h1>Crear cuenta:</h1>
        <form [formGroup]="formularioRegistro" (submit)="enviar()" class="formulario">
            <h4>Datos del usuario</h4>
            <div class="inputs">
                <label for="">
                    Nombre
                    <input type="text" formControlName="nombre" [(ngModel)]="repuesta.nombre">
                </label>
                <label for="">
                    Apellido
                    <input type="text" formControlName="apellido" [(ngModel)]="repuesta.apellido">
                </label>
                <label for="">
                    Teléfono
                    <input type="text" formControlName="telefono" [(ngModel)]="repuesta.telefono">
                </label>
                <label for="">
                    Correo
                    <input type="text" formControlName="correo" [(ngModel)]="repuesta.correo">
                </label>
                <label for="">
                    Contraseña
                    <input type="password" formControlName="password" [(ngModel)]="repuesta.password">
                </label>
            </div>
            <h4>Datos de facturación</h4>
            <div class="inputs">
                <label for="">
                    Razón social
                    <input type="text" formControlName="nombre_direccion_facturacion" [(ngModel)]="repuesta.nombre_direccion_facturacion">
                </label>
                <label for="">
                    RFC
                    <input type="text" formControlName="rfc_direccion_facturacion" [(ngModel)]="repuesta.rfc_direccion_facturacion">
                </label>
                <label for="">
                    CP
                    <input type="text" formControlName="codigo_postal_direccion_facturacion" [(ngModel)]="repuesta.codigo_postal_direccion_facturacion">
                </label>
            </div>
            <re-captcha formControlName="recaptchaReactive"></re-captcha>

            <button type="submit" [disabled]="formularioRegistro.invalid" class="button">Crear cuenta</button>

            <a routerLink="/login">Regresar</a>
        </form>
    </div>
</div>
