<div class="perfil">
    <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="sin-productoMensaje">
        <div class="callout" *ngFor="let alert of alerts" [@fadeInOut]>
            <h5 class="titulo">Producto sin existencia</h5>
            <p>{{alert.msg}} <a href="https://wa.me/{{dataSite.telefono_whatsapp}}?text={{alert.msgWhatsapp}}" target="_blank">Whatsapp</a></p>
            <!-- <button class="close-button" aria-label="Dismiss alert" type="button" ng-click="closeAlert($index)">
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
    </div> 
    <a [routerLink]="['/categoria', categoria.nombre_categoria_slug, categoria.id_categoria]">Regresar</a>
    <div class="producto">
        <div class="img">
            <img [src]="imagen_activa" alt="" src-fallback="{{ default }}">
        </div>
        <div class="texto">
            <h1>{{producto.descripcion}}</h1>
            <p><small>Código: {{producto.codigo}}</small></p>
            <div class="cont-precio">
                <p class="precio"><span class="capital">{{producto.unidad | lowercase}}</span> {{precio_producto | currency}} MXN</p>
                <!-- <p class="descuento">$17.50 MXN</p> -->
            </div>
            <p>Contenido por {{producto.unidad_mayoreo | lowercase}}: <b>{{producto.factor_web}} {{producto.unidad | lowercase}}</b></p>
            <p>Mínimo de compra: <b>{{producto.unidad_mayoreo | lowercase}}</b></p>
            <p>Precio por {{producto.unidad_mayoreo | lowercase}}: <b>{{precio_producto * producto.factor_web  | currency}} MXN</b></p>
            <p *ngIf="producto.dias_envio > 0">Días de envio: {{producto.dias_envio}}</p>
            <p class="color-t">Color: <b>{{productoSeleccionado.color}}</b></p>
            <div class="colores">
                <a class="item" [ngClass]="(rowColor.id == coloresSeleccion) ? 'active' : ''" (click)="changeColor(rowColor)" *ngFor="let rowColor of colores.slice(0, itemsToShow)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]>
                    <mat-icon>done</mat-icon>
                </a>
                <a (click)="itemsToShow = itemsToShow + 10;" *ngIf="colores.length > 10">Ver más</a>
            </div>
            <label class="opcion" for="" *ngIf="tallasActivo" [@fadeInOut]>
                Seleccione una opción
                <select [(ngModel)]="productoTallaSeleccionado">
                    <option value="">---</option>
                    <option [value]="row.id" *ngFor="let row of productoTallas | orderBy : 'nombre'">{{row.nombre}}</option>
                </select>
            </label>
            <label for="">
                Cantidad
                <ANY [ngSwitch]="cantidad_disponible_factorweb">
                    <small *ngSwitchCase="0" class="disponible-producto sin">Sin existencia</small>
                    <small *ngSwitchDefault class="disponible-producto">Existencia {{(cantidad_disponible_factorweb > 10) ? '+ 10' : cantidad_disponible_factorweb}} {{producto.unidad_mayoreo | lowercase}}</small>
                </ANY>
                <div class="mas-menos">
                    <button (click)="opcionCantidad(0)">-</button>
                    <input type="number" [(ngModel)]="cantidadProducto" (change)="cambiarCantidadProducto()">
                    <button (click)="opcionCantidad(1)">+</button>
                </div>
            </label>
            <small># de {{producto.unidad | lowercase}}: {{producto.minimo_compra * cantidadProducto}}</small>
            <h3 class="total">Total <b>{{(precio_producto * cantidadProducto) * producto.minimo_compra  | currency}} MXN</b></h3>
            <button (click)="agregarCarrito()" class="button" [disabled]="(cantidadProducto == 0) ? true : false">Agregar al carrito</button>
            <div class="acoordion">
                <div class="item">
                    <a class="header-acoordion" (click)="opcionOpen = (opcionOpen == 1) ? 0 : 1;">
                        <h1>Detalles del producto</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion" *ngIf="opcionOpen == 1">
                        <p>{{producto.nombre}}</p>
                    </div>
                </div>
                <!-- <div class="item">
                    <a class="header-acoordion" (click)="opcionOpen = (opcionOpen == 2) ? 0 : 2;">
                        <h1>Envios y paqueteria</h1>
                        <span>+</span>
                    </a>
                    <div class="contenido-acoordion" *ngIf="opcionOpen == 2">
                        <p>Informacion de envio</p>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="scroll-productos-vertical">
            <h4 *ngIf="productosComplementarios.length > 0" [@fadeInOut]>También te puede interesar</h4>
            <ngx-slick-carousel class="items" #slickModal="slick-carousel" [config]="slideConfigVertical" *ngIf="productosComplementarios.length > 0" [@fadeInOut]>
                <a [routerLink]="['/producto/', rowProducto.categorias[0].id, rowProducto.codigo]" ngxSlickItem *ngFor="let rowProducto of productosComplementarios" class="item">
                    <div class="img">
                        <img [src]="url+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'" alt="" src-fallback="{{ default }}">
                    </div>
                    <div class="texto">
                        <p>{{rowProducto.nombre}}</p>
                        <p class="precio">{{rowProducto.unidad_mayoreo}}: {{rowProducto.precio_neto * rowProducto.factor_web | currency}} MXN</p>
                    </div>
                </a>
            </ngx-slick-carousel>
        </div>
    </div>
    <div class="scroll-productos" *ngIf="productosSimilares.length > 0" [@fadeInOut]>
        <h1>Productos similares</h1>
        <ngx-slick-carousel class="lista-items" #slickModal="slick-carousel" [config]="slideConfig">
            <a [routerLink]="['/producto/', rowProducto.categorias[0].id, rowProducto.codigo]" ngxSlickItem *ngFor="let rowProducto of productosSimilares" class="item">
                <div class="img">
                    <img [src]="url+'/'+rowProducto.codigo + '/750_' + rowProducto.codigo + '.webp'" alt="" src-fallback="{{ default }}">
                </div>
                <div class="info">
                    <div class="colores">
                        <span *ngFor="let rowColor of rowProducto.colores.slice(0, 5)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]></span>
                        <span class="mas" *ngIf="rowProducto.colores.length > 5" [@fadeInOut]>15+</span>
                    </div>
                    <p>{{rowProducto.nombre}}</p>
                    <p>Código: {{rowProducto.codigo}}</p>
                    <p class="precio">{{rowProducto.unidad_mayoreo}}: {{rowProducto.precio_neto * rowProducto.factor_web | currency}} MXN</p>
                </div>
            </a>
        </ngx-slick-carousel>
    </div>
</div>
