import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-modal-cambiar-password',
	templateUrl: './modal-cambiar-password.component.html',
	styleUrls: ['./modal-cambiar-password.component.scss']
})
export class ModalCambiarPasswordComponent {
	repuesta: any = {};
	usuario: number;
	modalFormulario = this._formBuilder.group({
		password_actual: [this.repuesta.password_actual, Validators.required],
		password_cliente: [this.repuesta.password_cliente, Validators.required],
	});

	constructor(
		private _formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ModalCambiarPasswordComponent>,
		private apiServicio: ApiService,
		private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
	) {
		this.usuario = dataDialog.usuario;
 	}

	closeDialog() {
		this.dialogRef.close();
	}

	enviar() {
		this.apiServicio.actualizarPassword(this.usuario, this.repuesta)
		.subscribe(
			response => {
				if(response.estatus == 200) {
					this.dialogRef.close();
					this.mensaje(response.mensaje);
				} else {
					this.mensaje(response.mensaje, 'alert-snack-bar');
				}
			},
			error => {
				
			});
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
