import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';

@Component({
	selector: 'app-procesar-pago-tarjeta',
	templateUrl: './procesar-pago-tarjeta.component.html',
	styleUrls: ['./procesar-pago-tarjeta.component.scss']
})

/* http://localhost:4200/pasarela-de-pago/procesar-pago-tarjeta?id=trzoyivtnr9booj3tzf2 */
export class ProcesarPagoTarjetaComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		private servicios: ApiService,
		private sessionServices: SessionService,
		private router: Router
	) { }
	

	private carritoSubscription!: Subscription;
	
	ngOnInit() {
		this.subs.push(this.route.queryParams.subscribe(params => {
			this.subs.push(this.sessionServices.sessionInfoObservable.subscribe(items => {
				if(!items) {
					this.regresarCarrito();
				}
				console.log(items);
				if(params['id']) {
					this.validarPago(params['id'], items);
				} else {
					this.mensaje('Error al leer el token', 'alert-snack-bar');
					this.regresarCarrito();
				}
			}));
		}));
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	validarPago(id: any, info: any) {
		this.subs.push(this.servicios.terminar3DSecure(id, info)
			.subscribe(
				response => {
					console.log(response);
					this.sessionServices.limpiarInfo();
					this.router.navigate(['/pasarela-de-pago/listo']);
				},
				error => {
					this.mensaje(error.mensaje, 'alert-snack-bar');
					this.regresarCarrito();
				}
			));
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}

	regresarCarrito() {
		console.log('entro a proceso tarjeta');
		this.router.navigate(['/carrito']);
	}
}
