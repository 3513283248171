import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: 'img' })
export class ImgLazyloadDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
      setTimeout(function() {
        let url: any = nativeElement.getAttribute('src');
        nativeElement.removeAttribute('src');
        //nativeElement.setAttribute('src', url);
        nativeElement.setAttribute('src', url+ `?v=${Math.random()}`);
      }, 0);
    } else {
      // fallback to IntersectionObserver
    }
  }
}
