import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ImgFallbackModule } from 'ngx-img-fallback';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgPipesModule} from 'ngx-pipes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './componentes/header/header.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { HomeComponent } from './componentes/paginas/home/home.component';
import { CategoriaComponent } from './componentes/paginas/categoria/categoria.component';
import { CarritoComponent } from './componentes/paginas/carrito/carrito.component';
import { PasarelaDePagoComponent } from './componentes/paginas/pasarela-de-pago/pasarela-de-pago.component';
import { CargarScriptsService } from './core/servicios/cargar-scripts.service';
import { SucursalesComponent } from './componentes/paginas/sucursales/sucursales.component';
import { LoginComponent } from './componentes/paginas/usuarios/login/login.component';
import { RegistroComponent } from './componentes/paginas/usuarios/registro/registro.component';
import { CuentaComponent } from './componentes/paginas/usuarios/cuenta/cuenta.component';
import { CustomPaginator } from './core/modulos/custom-paginacion/custom-paginacion.module';
import { PedidosComponent } from './componentes/paginas/usuarios/pedidos/pedidos.component';
import { BolsaTrabajoComponent } from './componentes/paginas/bolsa-trabajo/bolsa-trabajo.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StoreModule } from '@ngrx/store';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ModalAgregarDireccionComponent } from './componentes/paginas/modales/modal-agregar-direccion/modal-agregar-direccion.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalEditarCuentaComponent } from './componentes/paginas/modales/modal-editar-cuenta/modal-editar-cuenta.component';
import { ModalEliminarComponent } from './componentes/paginas/modales/modal-eliminar/modal-eliminar.component';
import { ModalCambiarPasswordComponent } from './componentes/paginas/modales/modal-cambiar-password/modal-cambiar-password.component';
import { RecuperarComponent } from './componentes/paginas/usuarios/recuperar/recuperar.component';
import { CambiarPasswordComponent } from './componentes/paginas/usuarios/cambiar-password/cambiar-password.component';
import { ConfirmacionComponent } from './componentes/paginas/confirmacion/confirmacion.component';
import { ProcesarPagoTarjetaComponent } from './componentes/paginas/procesar-pago-tarjeta/procesar-pago-tarjeta.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ModalPoliticasComponent } from './componentes/paginas/modales/modal-politicas/modal-politicas.component';
import { ModalAvisoPrivacidadComponent } from './componentes/paginas/modales/modal-aviso-privacidad/modal-aviso-privacidad.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';
import { HistoriaComponent } from './componentes/paginas/historia/historia.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ImgLazyloadDirective } from './core/directiva/img-lazyload.directive';
import { PerfilComponent } from './componentes/paginas/perfil/perfil.component';
import { NgOptimizedImage } from '@angular/common';
import { ContactoComponent } from './componentes/paginas/contacto/contacto.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CategoriaComponent,
    PerfilComponent,
    CarritoComponent,
    PasarelaDePagoComponent,
    SucursalesComponent,
    LoginComponent,
    RegistroComponent,
    CuentaComponent,
    PedidosComponent,
    BolsaTrabajoComponent,
    ModalEditarCuentaComponent,
    ModalCambiarPasswordComponent,
    ModalAgregarDireccionComponent,
    ModalEliminarComponent,
    RecuperarComponent,
    CambiarPasswordComponent,
    ConfirmacionComponent,
    ProcesarPagoTarjetaComponent,
    ModalAvisoPrivacidadComponent,
    ModalPoliticasComponent,
    HistoriaComponent,
    ImgLazyloadDirective,
    ContactoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTabsModule,
    NgxCaptchaModule,
    HttpClientModule,
		NgxPaginationModule,
    Ng2SearchPipeModule,
    ImgFallbackModule,
    MatProgressSpinnerModule,
    NgPipesModule,
    StoreModule.forRoot({}, {}),
    MatSnackBarModule,
    MatDialogModule,
    NgxSliderModule,
    MatCheckboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgOptimizedImage
  ],
  providers: [
    CargarScriptsService,
    {provide: MatPaginatorIntl, useValue: CustomPaginator() },
    {
      provide: RECAPTCHA_SETTINGS,

      //Validar Con Henry El Cambio De La APIKEY DEL RECAPTCH
      useValue: { siteKey: "6LenNx4qAAAAALxMIVZh87Cr08YMrgfB9LYZix7k" } as RecaptchaSettings,
      
      //Recaptcha original de henry
      //useValue: { siteKey: "6LchtgMqAAAAAM3s-I9qONAFJs9oENgamXPw3gi1" } as RecaptchaSettings,



    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
