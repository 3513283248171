import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-cambiar-password',
	templateUrl: './cambiar-password.component.html',
	styleUrls: ['./cambiar-password.component.scss']
})
export class CambiarPasswordComponent implements OnInit, OnDestroy {
	repuesta: any = {};
	token: any;
	formularioRegistro = this._formBuilder.group({
		password: [this.repuesta.password, Validators.required]
	});
	constructor(
		private _formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private servicios: ApiService,
		private snackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				if(params['token']) {
					this.token = params['token'];
				} else {
					this.router.navigate(['login']);
				}
			}
		);
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	enviar() {
		this.subs.push(this.servicios.recuperarCuentaToken(this.repuesta, this.token)
			.subscribe(
				response => {
					if (response.estatus == 20) {
						// setTimeout(() => {
						// this.router.navigate(['login']);
						// }, 1000);
						this.mensaje(response.mensaje);
					} else {
						this.mensaje(response.mensaje, 'alert-snack-bar');
					}
				},
				error => {
					this.mensaje(error.mensaje, 'alert-snack-bar');
					//his.groupForm.reset();
				}
			));
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
