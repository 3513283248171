import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/servicios/api.service';

@Component({
	selector: 'app-modal-editar-cuenta',
	templateUrl: './modal-editar-cuenta.component.html',
	styleUrls: ['./modal-editar-cuenta.component.scss']
})
export class ModalEditarCuentaComponent {
	repuesta: any = {};
	usuario: number;
	modalFormulario = this._formBuilder.group({
		nombre_cliente: [this.repuesta.nombre_cliente, Validators.required],
		celular_cliente: [this.repuesta.celular_cliente, Validators.required],
	});

	constructor(
		private _formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ModalEditarCuentaComponent>,
		private apiServicio: ApiService,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
	) { 
		this.repuesta = dataDialog.info;
		this.usuario = dataDialog.usuario;
	}

	closeDialog() {
		this.dialogRef.close();
	}

	enviar() {
		this.apiServicio.actualizarUsuario(this.usuario, this.repuesta)
		.subscribe(
			response => {
				this.dialogRef.close();
			},
			error => {
				
			});
	}
}
