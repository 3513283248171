import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, Validators } from 'node_modules/@angular/forms';
import { ApiService } from 'src/app/core/servicios/api.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-historia',
	templateUrl: './historia.component.html',
	styleUrls: ['./historia.component.scss']
})
export class HistoriaComponent {
	default = environment.APIEndpoint + '/assets/img/default.png';
	sucursales: any = [
		{
			"imagen": './assets/img/sucursales/Centro.jpg',
			"id_sucursal": "8",
			"nombre_sucursal": "Mérida Centro",
			"nombre_sucursal_slug": "merida-centro",
			"fecha_apertura": "2013-02-28",
			"latitud_sucursal": "21.062832",
			"longitud_sucursal": "-89.64527599999997",
			"rango_servicio": "2.50",
			"calle": "Calle 52",
			"numero_exterior": "455",
			"numero_interior": "B",
			"cruzamiento": "50 y  52",
			"colonia": "Col. Centro",
			"codigo_postal": "97000",
			"ciudad": "Mérida",
			"estado": "Yucatán",
			"pais": "México",
			"horarios": "Lunes a Sábados de 9 am a 6 pm",
			"telefonos": "999 930 12 00",
			"whatsapp": "",
			"id_empresa": "1",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": "2008-08-16 12:56:00",
			"actualizado_sucursal": "2022-12-06 16:44:35",
			"url_sucursal": "https://www.google.com/maps/place/Assis,+M%C3%A9rida+Centro./@20.96423,-89.617904,15z/data=!4m5!3m4!1s0x0:0x2df6051c3015574e!8m2!3d20.96423!4d-89.617904",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14902.83283130259!2d-89.617904!3d20.96423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2df6051c3015574e!2sAssis%2C%20M%C3%A9rida%20Centro.!5e0!3m2!1ses!2smx!4v1670366661740!5m2!1ses!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		},
		{
			"imagen": './assets/img/sucursales/Norte.jpg',
			"id_sucursal": "9",
			"nombre_sucursal": "Mérida Norte",
			"nombre_sucursal_slug": "merida-norte",
			"fecha_apertura": "0000-00-00",
			"latitud_sucursal": "20.996322",
			"longitud_sucursal": "-89.61049500000001",
			"rango_servicio": "2.50",
			"calle": "Prolongación Paseo de Montejo",
			"numero_exterior": "98",
			"numero_interior": "",
			"cruzamiento": "19 y 21",
			"colonia": "México",
			"codigo_postal": "",
			"ciudad": "Mérida",
			"estado": "Yucatán",
			"pais": "México",
			"horarios": "Lunes a Sabados de 11 am a 8 pm",
			"telefonos": "999 944 52 52",
			"whatsapp": "",
			"id_empresa": "1",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": "2008-08-16 17:41:00",
			"actualizado_sucursal": "2022-12-06 16:45:01",
			"url_sucursal": "https://www.google.com/maps/place/Assis,+M%C3%A9rida+Norte/@21.0031515,-89.614577,15z/data=!4m5!3m4!1s0x0:0x44f448472272db8f!8m2!3d21.0031515!4d-89.614577",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14898.950549837606!2d-89.614577!3d21.0031515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x44f448472272db8f!2sAssis%2C%20M%C3%A9rida%20Norte!5e0!3m2!1ses!2smx!4v1670366686992!5m2!1ses!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		},
		{
			"imagen": './assets/img/sucursales/Charly.jpg',
			"id_sucursal": "10",
			"nombre_sucursal": "Cancún Charly",
			"nombre_sucursal_slug": "cancun-charly",
			"fecha_apertura": "0000-00-00",
			"latitud_sucursal": "21.02633",
			"longitud_sucursal": "-89.64880800000003",
			"rango_servicio": "2.50",
			"calle": "Av. Tulum",
			"numero_exterior": "Lote 4",
			"numero_interior": "",
			"cruzamiento": "Esq. Chichen Itza",
			"colonia": "Sm 64 Mz 81",
			"codigo_postal": "",
			"ciudad": "Cancún",
			"estado": "Quintana Roo",
			"pais": "México",
			"horarios": "Lunes a Sábados de 10 am a 7 pm",
			"telefonos": "998 887 51 66",
			"whatsapp": "",
			"id_empresa": "1",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": "2009-08-16 18:27:00",
			"actualizado_sucursal": "2022-12-06 16:46:09",
			"url_sucursal": "https://www.google.com/maps/place/ASSIS+Charly/@21.1708058,-86.8278154,17z/data=!3m1!4b1!4m5!3m4!1s0x8f4c2d499729abe5:0xf414816572041b5f!8m2!3d21.1706839!4d-86.8255573",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.537265320342!2d-86.82781538526592!3d21.17080578592164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2d499729abe5%3A0xf414816572041b5f!2sASSIS%20Charly!5e0!3m2!1ses!2smx!4v1670366754477!5m2!1ses!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		},
		{
			"imagen": './assets/img/sucursales/Kabah.jpg',
			"id_sucursal": "39",
			"nombre_sucursal": "Cancun Kabah",
			"nombre_sucursal_slug": "cancun-kabah",
			"fecha_apertura": "0000-00-00",
			"latitud_sucursal": "",
			"longitud_sucursal": "",
			"rango_servicio": "0.00",
			"calle": "Calle Prol. Av. Kabáh 94",
			"numero_exterior": null,
			"numero_interior": null,
			"cruzamiento": null,
			"colonia": null,
			"codigo_postal": "77508",
			"ciudad": "Cancún",
			"estado": "Quintana Roo",
			"pais": null,
			"horarios": "10 a.m. a 8 p.m.",
			"telefonos": "9988808088",
			"whatsapp": null,
			"id_empresa": "0",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": null,
			"actualizado_sucursal": null,
			"url_sucursal": "https://www.google.com/maps/place/Assis+Telas/@21.1541569,-86.8463284,17z/data=!3m1!4b1!4m5!3m4!1s0x8f4c2b8b630e7c29:0xb7eb352945a20128!8m2!3d21.1541569!4d-86.8441397",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.955808294451!2d-86.84632838526623!3d21.15415688593061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b8b630e7c29%3A0xb7eb352945a20128!2sAssis%20Telas!5e0!3m2!1ses!2smx!4v1670366933223!5m2!1ses!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		},
		{
			"imagen": './assets/img/sucursales/Campeche.jpg',
			"id_sucursal": "40",
			"nombre_sucursal": "Campeche",
			"nombre_sucursal_slug": "campeche",
			"fecha_apertura": "0000-00-00",
			"latitud_sucursal": "",
			"longitud_sucursal": "",
			"rango_servicio": "0.00",
			"calle": "Av Adolfo Ruiz Cortinez, Zona Centro",
			"numero_exterior": null,
			"numero_interior": null,
			"cruzamiento": null,
			"colonia": null,
			"codigo_postal": "24000",
			"ciudad": "Campeche",
			"estado": "Campeche",
			"pais": null,
			"horarios": "9 a.m. a 6 p.m.",
			"telefonos": "9818115020",
			"whatsapp": null,
			"id_empresa": "0",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": null,
			"actualizado_sucursal": null,
			"url_sucursal": "https://www.google.com/maps/place/Assis,+Campeche/@19.8491647,-90.5408815,17z/data=!3m1!4b1!4m5!3m4!1s0x1150b155dcab276b:0x63d2345db4963a06!8m2!3d19.8491648!4d-90.5363968",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3752.7822408501575!2d-90.54088147698695!3d19.849164743945856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1150b155dcab276b%3A0x63d2345db4963a06!2sAssis%2C%20Campeche!5e0!3m2!1ses!2smx!4v1670367072346!5m2!1ses!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		},
		{
			"imagen": './assets/img/sucursales/Sport.jpg',
			"id_sucursal": "42",
			"nombre_sucursal": "Assis Sport",
			"nombre_sucursal_slug": "assis-sport",
			"fecha_apertura": "0000-00-00",
			"latitud_sucursal": "",
			"longitud_sucursal": "",
			"rango_servicio": "0.00",
			"calle": "C. 65",
			"numero_exterior": null,
			"numero_interior": null,
			"cruzamiento": "52 y 54",
			"colonia": "centro",
			"codigo_postal": "97000",
			"ciudad": "Mérida",
			"estado": "Yucatán",
			"pais": null,
			"horarios": "9:00 am a 6:00 pm",
			"telefonos": "",
			"whatsapp": null,
			"id_empresa": "0",
			"estatus_sucursal": "1",
			"estatus_mapa": "1",
			"creado_sucursal": null,
			"actualizado_sucursal": null,
			"url_sucursal": "https://goo.gl/maps/Tj51kANhQXfkG2LL9",
			"iframe_sucursal": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d730.8770178782098!2d-89.61858049900071!3d20.964297697181596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5671664ed012f9%3A0x4340be5f476dfa45!2sASSIS%20SPORT%20telas!5e0!3m2!1ses-419!2smx!4v1671690414522!5m2!1ses-419!2smx\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>"
		}
	];
	slides = [
		//{ img: './assets/img/pruebas/banner.png' },
		{ img: './assets/img/pruebas/banner-2.png' },
		{ img: './assets/img/pruebas/banner-2.png' },
		{ img: './assets/img/pruebas/banner-2.png' },
		{ img: './assets/img/pruebas/banner-2.png' },

	];
	slideConfig = {
		dots: false,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
	};

	slideConfigVertical = {
		slidesToShow: 3,
		slidesToScroll: 1,
		//autoplay: true,
		vertical: true,
  		verticalSwiping: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					vertical: false,
  					verticalSwiping: false,
				}
			}
		]
	};

	slideConfigPromociones = {
		slidesToShow: 2,
		slidesToScroll: 1,
		//autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 1030,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	//sucursales: any = [];
	respuesta: any = {};
	formContacto: any = this._formBuilder.group({
		recaptchaReactive: new FormControl(this.respuesta.recaptchaReactive, Validators.required),
		nombre: new FormControl(this.respuesta.nombre, Validators.required),
		telefono: new FormControl(this.respuesta.telefono, Validators.required),
		correo: new FormControl(this.respuesta.correo, Validators.required),
		mensaje: new FormControl(this.respuesta.mensaje, Validators.required),
	});

	constructor(
		private _formBuilder: FormBuilder,
		private services: ApiService,
		private snackBar: MatSnackBar
	) {
	}

	ngOnInit() {
		//this.getSucursales();
		this.seleccionSucursal(0);
	}

	sucursal: any = {};

	seleccionSucursal(key: any) {
		//console.log(key.target.parentNode.getAttribute('data-key'));
		this.sucursal = this.sucursales[key];
	}

	afterChange(e: any) {
		const elemsInit: NodeListOf<HTMLElement> = e.event.target.querySelectorAll('.button-sucursal');
		let elements: HTMLElement[] = [];
		for (let k in elemsInit) {
			elements.push(elemsInit[k]);
		}
		elements = elements.filter(elem => typeof (elem) === 'object');
		for (const element of elements) {
			//element.addEventListener('click', this.seleccionSucursal.bind(this));
			// function(this) {
			// 	console.log(this);
			// 	//(this.target as HTMLInputElement).data('data-key');
			// 	const dataKey: HTMLElement = this;
				
			// 	this.seleccionSucursal.bind(dataKey.getAttribute('data-key'))
			// }
		}
	}

	enviar() {
		this.services.enviarCorreo(this.respuesta).subscribe(
			response => {
				if(response.estatus == 20) {
					this.mensaje(response.mensaje);
					this.respuesta = {};
				} else {
					this.mensaje(response.mensaje, 'alert-snack-bar');
				}
			},
			error => {
				this.mensaje('Por favor vuelve a intentar mandar tu correo', 'alert-snack-bar');
			}
		);
	}

	getSucursales() {
		this.services.getSucursales().subscribe(
			response => {
				this.sucursales = response.return;
			},
			error => {

			}
		);
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
