import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { CargarScriptsService } from 'src/app/core/servicios/cargar-scripts.service';
import { CarritoService } from 'src/app/core/servicios/carrito.service';
import { SessionService } from 'src/app/core/servicios/session.service';
import { environment } from 'src/environments/environment';
declare var OpenPay: any;

@Component({
	selector: 'app-pasarela-de-pago',
	templateUrl: './pasarela-de-pago.component.html',
	styleUrls: ['./pasarela-de-pago.component.scss']
})
export class PasarelaDePagoComponent implements OnInit, OnDestroy {
	dataPago: any = {};
	enviarOfertas: boolean = true;
	envioEstafeta: any = {};
	slideConfigVertical = {
		slidesToShow: 3,
		slidesToScroll: 1,
		//autoplay: true,
		vertical: true,
		verticalSwiping: true,
		autoplaySpeed: 2000,
		prevArrow: "<button type='button' class='slick-prev pull-left'><img src='../../../../assets/img/iconos/izquierda.png'></button>",
		nextArrow: "<button type='button' class='slick-next pull-right'><img src='../../../../assets/img/iconos/derecha.png'></button>",
		responsive: [
			{
				breakpoint: 1421,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					vertical: false,
					verticalSwiping: false,
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					vertical: false,
					verticalSwiping: false,
				}
			}
		]
	};
	tarjeta: any = {};
	deviceSessionId: any;
	idUsuario: any = {};
	url = environment.APIEndpoint + '/assets/productos/';
	default = environment.APIEndpoint + '/assets/img/default.png';
	public carrito$: Observable<any>;
	direccion: any = {};
	direccion_temporal: any = {};
	cliente_temporal: any = {};
	carrito: any = [];
	loading: boolean = false;
	limitePago = 70000;
	totales = {
		'descuento': 0,
		'subtotal': 0,
		'total': 0,
		'envio': 0,
		'iva': 0
	}
	constructor(
		private servicios: ApiService,
		private carritoService: CarritoService,
		private cargarScript: CargarScriptsService,
		private router: Router,
		private snackBar: MatSnackBar,
		private sessionServices: SessionService,
		private _formBuilder: FormBuilder,

	) {
		this.carrito$ = carritoService.carritoObservable;
		this.cargarScript.cargar(['openpay.v1.min', 'openpay-data.v1.min']);
	}

	formPago = this._formBuilder.group({
		tipo_pago: new FormControl(this.dataPago.tipo_pago, Validators.required),
		recaptchaReactive: new FormControl(this.dataPago.recaptchaReactive, Validators.required),
		nombre_tarjeta: new FormControl(this.tarjeta.nombre_tarjeta),
		num_t: new FormControl(this.tarjeta.num_t),
		expm: new FormControl(this.tarjeta.expm),
		expy: new FormControl(this.tarjeta.expy),
		xx: new FormControl(this.tarjeta.xx)
	});

	ngOnInit(): void {
		// this.formPago = new FormGroup({
		// 	tipo_pago: new FormControl(this.dataPago.tipo_pago, Validators.required),
		// 	nombre_tarjeta: new FormControl(this.tarjeta.nombre_tarjeta),
		// 	num_t: new FormControl(this.tarjeta.num_t),
		// 	expm: new FormControl(this.tarjeta.expm),
		// 	expy: new FormControl(this.tarjeta.expy),
		// 	xx: new FormControl(this.tarjeta.xx)
		// });
		this.dataPago.tipo_pago = 6;
		this.subs.push(this.sessionServices.sessionObservable.subscribe(cliente => {
			console.log(cliente);
			this.idUsuario = cliente.id_cliente;
			if(!this.idUsuario) {
				this.redireccionCarrito();
			}
			this.getUsuario();
		}));

		this.subs.push(this.carritoService.carritoObservable.subscribe(items => {
			// if(items.lenght == 0 || items.lenght == undefined) {
			if(!items) {
				this.redireccionCarrito();
			}
			let subtotal = 0;
			items.forEach((element: any) => {
				subtotal = subtotal + element.total;
			});
			this.totales.subtotal = subtotal;
			this.getEstafeta(items);
			this.carrito = items;
		}));
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	getUsuario() {
		this.subs.push(this.servicios.getUsuario(this.idUsuario)
		.subscribe(
			response => {
				this.cliente_temporal.nombre = response.return.nombre_cliente;
				this.cliente_temporal.correo = response.return.correo_cliente;
				this.cliente_temporal.telefono = response.return.celular_cliente;
				this.cliente_temporal.id_cliente = this.idUsuario;
				this.cliente_temporal.id_intelisis = response.return.respuesta_intelisis.data.cliente;
				this.cliente_temporal.nombre_intelisis = response.return.respuesta_intelisis.data.nombre;
			},
			error => {
				
			}));
	}

	getEstafeta(carrito: any) {
		this.subs.push(this.servicios.getEstafeta(carrito)
		.subscribe(
			response => {
				this.envioEstafeta.peso = response.peso_total;
				this.envioEstafeta.cajas = response.cajas;
				this.envioEstafeta.monto = response.monto;
				this.envioEstafeta.datosExtra = response;

				this.totales.envio = response.monto;
				this.totales.total = this.totales.envio + this.totales.subtotal;
				console.log(this.totales);
				if(this.totales.total && this.totales.total == 0) {
					this.mensaje('Error para poder realizar el pago tiene que ser mayor a cero', 'alert-snack-bar');
					this.redireccionCarrito();
				}
			},
			error => {
				this.mensaje('Inicia sesión antes de terminar la compra', 'alert-snack-bar');
				this.redireccionCarrito();
			}
		));
		this.subs.push(this.servicios.getDireccionDefault(this.idUsuario)
			.subscribe(
				response => {
					this.direccion = response.return;
					this.direccion_temporal.calle = this.direccion.direccion_envio;
					this.direccion_temporal.n_ext_direccion_envio = this.direccion.n_ext_direccion_envio;
					this.direccion_temporal.codigo_estado = this.direccion.codigo_estado;
					this.direccion_temporal.codigo_municipio = this.direccion.codigo_municipio;
					this.direccion_temporal.codigo_postal_direccion_envio = this.direccion.codigo_postal_direccion_envio;
					this.direccion_temporal.colonia_direccion_envio = this.direccion.colonia_direccion_envio;
					this.direccion_temporal.ciudad_direccion_envio = this.direccion.ciudad_direccion_envio;
					this.direccion_temporal.estado_direccion_envio = this.direccion.estado_direccion_envio;
					this.direccion_temporal.id_direccion_intelisis = this.direccion.id_direccion_intelisis;
					this.direccion_temporal.tipo_envio = 1;
					this.direccion_temporal.id_direccion_envio = this.direccion.id_direccion_envio;
					console.log(this.direccion_temporal);
				},
				error => {
					this.mensaje('Inicia sesión antes de terminar la compra', 'alert-snack-bar');
					this.redireccionCarrito();
				}
			));
	}

	enviarPago(): void {
		this.loading = true;
		OpenPay.setId(environment.idOpenPay);
		OpenPay.setApiKey(environment.llavePublicaOpenPay);
		//OpenPay.setSandboxMode(environment.sandBoxOpenPay);
		this.deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
		console.log(this.dataPago);
		var success_callbak = (response: any) => {
			console.log(response);
			let token_id = response.data.id;
			console.log(token_id);
			this.subs.push(this.servicios.generarPago(this.carrito, this.cliente_temporal, this.direccion_temporal, this.dataPago, this.envioEstafeta, this.deviceSessionId, token_id)
			.subscribe(
				response => {
					console.log(response);
					this.loading = false;
					//if(response.estatus == 20) {
						this.sessionServices.sessionInfoObservableData = response.return;
						window.location.href = response.return.url;
					// } else {
					// 	this.mensaje(response.mensaje, 'alert-snack-bar');
					// }
				}, error => {
					this.loading = false;
					this.mensaje(error.mensaje, 'alert-snack-bar');
					this.redireccionCarrito();
				}));
		};
	
		var error_callbak = (response: any) => {
			console.log(response);
			this.loading = false;
				this.mensaje(response.data.description, 'alert-snack-bar');
		};
		
		if(this.dataPago.tipo_pago == 6) {
			OpenPay.token.create(
				{
					"card_number": this.dataPago.num_t,
					"holder_name": this.dataPago.nombre_tarjeta,
					"expiration_year": this.dataPago.expy,
					"expiration_month": this.dataPago.expm,
					"cvv2": this.dataPago.xx
				}, success_callbak, error_callbak);
		} else {
			this.subs.push(this.servicios.generarPago(this.carrito, this.cliente_temporal, this.direccion_temporal, this.dataPago, this.envioEstafeta, this.deviceSessionId)
			.subscribe(
				response => {
					console.log(response);
					this.loading = false;
					if(response.estatus == 20) {
						console.log('entro');
						//this.carritoService.emptyCarrito();
						this.router.navigate(['pasarela-de-pago/listo']);
					} else {
						this.mensaje(response.mensaje, 'alert-snack-bar');
					}
				}, error => {
					this.loading = false;
					this.mensaje(error.mensaje, 'alert-snack-bar');
					this.redireccionCarrito();
				}));
		}
	}

	enviarDatosPago(carrito: any, usuario: any, direccion: any, dataPago: any, envioEstafeta: any, deviceSessionId: any, token: any = '') {
		this.subs.push(this.servicios.generarPago(carrito, usuario, direccion, dataPago, envioEstafeta, deviceSessionId, token)
			.subscribe(
				response => {
					console.log(response);
					this.router.navigate(['/pasarela-de-pago/listo']);
				}, error => {
					this.redireccionCarrito();
				}));
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}

	redireccionCarrito() {
		this.snackBar.open('Tiene que iniciar sesion para poder terminar tu compra.', '', {
			duration: 10000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: ['alert-snack-bar']
		});
		this.router.navigate(['/carrito'], { queryParams: {'inicio-sesion': true} });
	}
}
