<footer>
    <img src="../../../assets/img/logo.png" alt="" id="logo">
    <div class="menu">
        <ul>
            <li><a routerLink="/">Inicio</a></li>
            <!-- <li><a routerLink="/historia">Quienes somos</a></li> -->
            <!-- <li><a routerLink="/sucursales">Sucursales</a></li> -->
            <li><a routerLink="/bolsa-trabajo">Bolsa de trabajo</a></li>
            <li><a href="http://assiscorpo1.dyndns.org:8020/" target="_blank">Facturación</a></li>
            <li><a routerLink="/contacto">Contacto</a></li>
            <!--<li><a routerLink="/contacto">Facturacion</a></li>-->
            <li><a href="http://assiscorpo1.dyndns.org:8020/">Facturacion</a></li>


        </ul>
        <p>© Copyright Assis tu vestir S.A. de C.V. 2023</p>
        <div class="aviso-politicas">
            <a (click)="openModalAviso()">Aviso de privacidad</a>
            <a (click)="openModalPoliticas()">Políticas</a>
        </div>
    </div>
    <ul class="redes">
        <li>
            <a href="{{dataSite.link_facebook}}" target="_blank" *ngIf="dataSite.link_facebook">
                <img src="../../../assets/img/iconos/facebook.svg" alt="">
            </a>
        </li>
        <li>
            <a href="{{dataSite.link_instagram}}" target="_blank" *ngIf="dataSite.link_instagram">
                <img src="../../../assets/img/iconos/instagram.svg" alt="">
            </a>
        </li>
        <li>
            <a href="{{dataSite.link_tiktok}}" target="_blank" *ngIf="dataSite.link_tiktok">
                <img src="../../../assets/img/iconos/tiktok.svg" alt="">
            </a>
        </li>
        <li>
            <a href="{{dataSite.link_youtube}}" target="_blank" *ngIf="dataSite.link_youtube">
                <img src="../../../assets/img/iconos/youtube.svg" alt="">
            </a>
        </li>
    </ul>
</footer>

<a href="https://wa.me/{{dataSite.telefono_whatsapp}}" target="_blank" class="whatsapp-scroll">
    <img src="../../../assets/img/whatsapp.svg" alt="">
</a>
