// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIEndpoint: "https://panel.tiendasassis.com.mx",
  urlPdfOpenPay: "https://dashboard.openpay.mx",
  idOpenPay: "m7fd29q7bdcpb2xmpwki",
  llavePublicaOpenPay: "pk_05d59257682b4e108266a6baa7763d21",
  sandBoxOpenPay: false,
  googleAnalyticsId: 'G-6R5KQXJG25'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
