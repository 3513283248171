import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { CarritoService } from 'src/app/core/servicios/carrito.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-carrito',
	templateUrl: './carrito.component.html',
	styleUrls: ['./carrito.component.scss']
})
export class CarritoComponent implements OnInit, OnDestroy {

	default = environment.APIEndpoint + '/assets/img/default.png';
	public carrito$: Observable<any>;
	inicioSesion: boolean = false;
	totales = {
		'descuento': 0,
		'subtotal': 0,
		'total': 0,
		'envio': 0,
		'iva': 0
	}
	constructor(
		private servicios: ApiService,
		private route: ActivatedRoute,
		private carritoService: CarritoService,
	) {
		this.carrito$ = carritoService.carritoObservable;
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}
	
	ngOnInit() {
		this.subs.push(this.carritoService.carritoObservable.subscribe(items => {
			let total = 0;
			items.forEach((element: any) => {
				total = total + element.total;
			});
			this.totales.total = total;
		}));
		this.subs.push(this.route.queryParams.subscribe(params => {
			console.log(params);
			if(params['inicio-sesion']) {
				this.inicioSesion = params['inicio-sesion'];
			}
		}));
	}

	deleteItem(key: number) {
		this.carritoService.deleteItem(key);
	}

	changeCantidad(key: number, cantidad: number) {
		this.carritoService.editItem(key, cantidad);
	}

	emptyCarrito() {
		this.carritoService.emptyCarrito();
	}
}
