import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent {
  
  respuesta: any = {};
	formContacto: any = this._formBuilder.group({
		recaptchaReactive: new FormControl(this.respuesta.recaptchaReactive, Validators.required),
		nombre: new FormControl(this.respuesta.nombre, Validators.required),
		telefono: new FormControl(this.respuesta.telefono, Validators.required),
		correo: new FormControl(this.respuesta.correo, Validators.required),
		mensaje: new FormControl(this.respuesta.mensaje, Validators.required),
	});

	constructor(
		private _formBuilder: FormBuilder,
		private servicios: ApiService,
		private snackBar: MatSnackBar,
		private elRef: ElementRef,
		private serviciosSite: SessionService
	) {
	}
	dataSite: any = {};
	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}
  
  enviar() {
		this.servicios.enviarCorreo(this.respuesta).subscribe(
			response => {
				if (response.estatus == 20) {
					this.mensaje(response.mensaje);
					this.respuesta = {};
				} else {
					this.mensaje(response.mensaje, 'alert-snack-bar');
				}
			},
			error => {
				this.mensaje('Por favor vuelve a intentar mandar tu correo', 'alert-snack-bar');
			}
		);
	}

	mensaje(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
