import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core/servicios/api.service';
import { SessionService } from 'src/app/core/servicios/session.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	correcto: boolean = false;
	formLogin!: FormGroup;
	dataUsuarios: any = {};
	error_m: any = {};
	loading: boolean = false;
	constructor(
		private router: Router,
		private servicios: ApiService,
		private fb: FormBuilder,
		private sessionServices: SessionService,
		private snackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.formLogin = this.fb.group({
			usuario: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required),
			recaptchaReactive: new FormControl('', Validators.required),
		});
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}

	onSubmitLogin() {
		this.loading = true;
		this.dataUsuarios.email_usuario = this.formLogin.value.usuario;
		this.dataUsuarios.password_usuario = this.formLogin.value.password;
		this.dataUsuarios.recaptchaReactive = this.formLogin.value.recaptchaReactive;
		this.subs.push(this.servicios.login(this.dataUsuarios)
			.subscribe(
				response => {
					if(response.estatus == 20) {
						this.correcto = true;
						this.sessionServices.sessionObservableData = response.return;
						setTimeout(() => {
							this.loading = false;
							this.router.navigate(['/cliente/mi-cuenta']);
						}, 1000);
					} else {
						this.correcto = false;
						this.loading = false;
						this.error(response.mensaje, 'alert-snack-bar');
					}
				},
				error => {
					this.error(error.mensaje);
					//his.groupForm.reset();
					this.loading = false;
				}
			));
	}

	error(mensaje: any, tipo: string = 'success-snack-bar') {
		this.snackBar.open(mensaje, '', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
			panelClass: [tipo]
		});
	}
}
