import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/core/servicios/session.service';
import { ModalAvisoPrivacidadComponent } from '../paginas/modales/modal-aviso-privacidad/modal-aviso-privacidad.component';
import { ModalPoliticasComponent } from '../paginas/modales/modal-politicas/modal-politicas.component';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	dataSite: any = {};
	constructor(
		private servicios: SessionService,
		private dialog: MatDialog
	) {
		this.dataSite = servicios.datosSite;
		servicios.datosSiteObs.subscribe(items => {
			this.dataSite = items;
		});
	}

	openModalAviso() {
		const dialogRef = this.dialog.open(
			ModalAvisoPrivacidadComponent,
			{
				data: {
					info: this.dataSite.aviso,
				}
			}
		);
	}

	openModalPoliticas() {
		const dialogRef = this.dialog.open(
			ModalPoliticasComponent,
			{
				data: {
					info: this.dataSite.politicas,
				}
			}
		);
	}
}
