import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	URL = environment.APIEndpoint + '/api';
	URLPago = environment.APIEndpoint + '/pagosapi';

	constructor(
		private http: HttpClient
	) { }

	getDataSite(): Observable<any> {
		return this.http.get(`${this.URL}/dataSite`);
	}

	getSucursales(): Observable<any> {
		return this.http.get(`${this.URL}/get_sucursales`);
	}

	getBanners(): Observable<any> {
		return this.http.get(`${this.URL}/get_banners`);
	}

	getBannersTienda(): Observable<any> {
		return this.http.get(`${this.URL}/get_banners_tienda`);
	}

	getBannersPromociones(): Observable<any> {
		return this.http.get(`${this.URL}/get_banners_promociones`);
	}

	getCategoria(id: number): Observable<any> {
		return this.http.get(`${this.URL}/get_categoria/${id}`);
	}

	getCategorias(): Observable<any> {
		return this.http.get(`${this.URL}/get_categorias`);
	}

	getCategoriasBanner(): Observable<any> {
		return this.http.get(`${this.URL}/get_categorias_banner`);
	}

	getProductosDescuento(): Observable<any> {
		return this.http.get(`${this.URL}/get_productos_descuento`);
	}

	getProductosDestacado(): Observable<any> {
		return this.http.get(`${this.URL}/get_productos_destacado`);
	}

	getCategoriaProductos($id: number, $buscar: string = ''): Observable<any> {
		return this.http.get(`${this.URL}/get_categoria_productos/${$id}/${$buscar}`);
	}

	getProducto($idCateogira: number, $idProducto: number): Observable<any> {
		//console.log(`${this.URL}/get_producto/${$idCateogira}/${$idProducto}`);
		return this.http.get(`${this.URL}/get_producto/${$idCateogira}/${$idProducto}`);
	}

	getProductosSimples(datos: any): Observable<any> {
		let data: any = {};
		data.datos = datos;
		return this.http.post(`${this.URL}/get_producto_similares`, data);
	}

	/* login */
	login(data: any): Observable<any> {
		return this.http.post(`${this.URL}/login`, data);
	}

	recuperarCuenta(datos: any): Observable<any> {
		let data: any = {};
		data.datos = datos;
		return this.http.post(`${this.URL}/recuperar`, data);
	}

	recuperarCuentaToken(datos: any, token: any): Observable<any> {
		let data: any = {};
		data.datos = datos;
		return this.http.post(`${this.URL}/recuperar_cuenta/${token}`, data);
	}

	verificarToken(data: any): Observable<any> {
		return this.http.get(`${this.URL}/verificarToken/${data}`);
	}

	verificarTokenCuenta(data: any): Observable<any> {
		return this.http.get(`${this.URL}/verificarTokenCuenta/${data}`);
	}

	/* Codigo postal */
	getCodigoPostal(cp: number): Observable<any> {
		return this.http.get(`${this.URL}/get_codigo_postal/${cp}`);
	}

	/* Usuario - dirección envio */
	getDireccionesEnvio(usuario: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/get_direcciones_envio`, data);
	}

	getDireccioneEnvio(usuario: number, id: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/get_direcciones_envio/${id}`, data);
	}

	defaultDireccion(id: any): Observable<any> {
		return this.http.get(`${this.URL}/establecerDireccion/${id}/true/1`,);
	}

	agregarDireccion(usuario: number, datos: any): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.datos = datos;
		return this.http.post(`${this.URL}/agregar_direccion_envio`, data);
	}

	editarDireccion(usuario: number, datos: any, id: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.datos = datos;
		return this.http.post(`${this.URL}/editar_direccion_envio/${id}`, data);
	}

	eliminarDireccion($id: any, usuario: any): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.id = $id;
		return this.http.post(`${this.URL}/eliminar_direccion_envio`, data);
	}

	/* Usuario - dirección de facturacion */
	getDireccionFacturacion(usuario: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/get_direcciones_facturacion`, data);
	}

	agregarDireccionFacturacion(usuario: number, id: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/agregar_direccion_facturacion/${id}`, data);
	}

	editarDireccionFacturacion(usuario: number, id: number, direccion: any): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.direccion = direccion;
		return this.http.post(`${this.URL}/editar_direccion_facturacion/${id}`, data);
	}

	getEstados(): Observable<any> {
		return this.http.get(`${this.URL}/estados`,);
	}

	getMunicipios(id: any): Observable<any> {
		return this.http.get(`${this.URL}/municipios/${id}`,);
	}

	/* Usuario */

	getUsuario(usuario: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/getCliente`, data);
	}

	actualizarUsuario(usuario: number, datos: any): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.datos = datos;
		return this.http.post(`${this.URL}/updateCliente`, data);
	}

	registrarCuentaUsuario(data: any): Observable<any> {
		return this.http.post(`${this.URL}/guardarCliente`, data);
	}

	actualizarPassword(usuario: number, datos: any): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.datos = datos;
		return this.http.post(`${this.URL}/updateClientePassword`, data);
	}

	registrarCliente(datos: any): Observable<any> {
		let data: any = {};
		data = datos;
		return this.http.post(`${this.URL}/registroUsuario`, data);
	}

	/* Pedidos */
	getPedidos(usuario: number, idIntelisis: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		data.idIntelisis = idIntelisis;
		return this.http.post(`${this.URL}/get_pedidos`, data);
	}

	/* Terminar pago */
	getDireccionDefault(usuario: number): Observable<any> {
		let data: any = {};
		data.usuario = usuario;
		return this.http.post(`${this.URL}/get_direcciones_envio_default`, data);
	}

	getEstafeta(carrito: any): Observable<any> {
		let data: any = {};
		data.carrito = carrito;
		return this.http.post(`${this.URL}/getEstafeta`, data);
	}

	generarPago(carrito: any, usuario: any, direccion: any, dataPago: any, envioEstafeta: any, deviceSessionId: any = '', token: any = ''): Observable<any> {
		let data: any = {};
		data.carrito = carrito;
		data.usuario = usuario;
		data.tipo_pago = dataPago.tipo_pago;
		data.recaptchaReactive = dataPago.recaptchaReactive;
		data.direccion = direccion;
		data.envio = envioEstafeta;
		data.token = token;
		data.deviceSessionId = deviceSessionId;
		return this.http.post(`${this.URLPago}/procesar_pago_cliente`, data);
	}

	terminar3DSecure(token: any = '', datos: any): Observable<any> {
		let data: any = {};
		data.token = token;
		data.datos = datos;
		return this.http.post(`${this.URLPago}/terminar3DSecure`, data);
	}

	enviarCorreo(datos: number): Observable<any> {
		let data: any = {};
		data.datos = datos;
		return this.http.post(`${this.URL}/enviar`, data);
	}
}
