<div class="carrito">
    <h1>Tu carrito</h1>
    <!-- <a [routerLink]="['/pasarela-de-pago/listo']">terminar</a> -->
    <div class="lista-items-totales">
        <div class="items">
            <div class="item" *ngIf="(carrito$ | async).length == 0">
                <div class="sin-items">
                    <h3>Sin productos</h3>
                </div>
            </div>
            <div class="item" *ngFor="let item of (carrito$ | async); let key = index">
                <div class="img">
                    <img [src]="item.opciones.img_producto" alt="" src-fallback="{{ default }}">
                </div>
                <div class="cont-info">
                    <div class="info">
                        <div class="texto-info">
                            <h1>{{item.name}}</h1>
                            <p>{{item.opciones.nombre_producto}}</p>
                        </div>
                        <div class="cantidad">
                            <label for="">
                                <input type="number" [(ngModel)]="item.qty" (change)="changeCantidad(key, item.qty)">
                            </label>
                        </div>
                        <div class="total">
                            <!-- <p class="descuento">{{}} MXN</p> -->
                            <p class="precio">{{item.price | currency}} MXN</p>
                            <p class="precio total">Total {{item.total | currency}} MXN</p>
                        </div>
                    </div>
                    <div class="opciones">
                        <!-- <p>Tamaño: Grande</p> -->
                        <p>Categoría: {{item.opciones.nombre_categoria}}</p>
                        <p>Web ID: {{item.opciones.codigo_producto}}</p>
                    </div>
                    <div class="opciones">
                        <a (click)="deleteItem(key)">Quitar</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="totales">
            <p *ngIf="inicioSesion">
                Para terminar tu compra necesitas registrarte o iniciar sesión puedes hacer <a [routerLink]="['/login']">click a aquí</a>
            </p>
            <a [routerLink]="['/pasarela-de-pago']" class="button full" [ngClass]="(carrito$ | async).length >= 2 ? 'scroll' : ''" *ngIf="(carrito$ | async).length > 0 ">Continuar con <!-- el  -->pago</a>
            <!-- <div class="item">
                <p>Subtotal</p>
                <p>$251 MXN</p>
            </div>
            <div class="item descuento">
                <p>Ahorraste</p>
                <p>$100 MXN</p>
            </div> -->
            <div class="item">
                <p><b>Total</b></p>
                <p><b>{{totales.total | currency}} MXN</b></p>
            </div>
            <div class="info">
                <a (click)="emptyCarrito()">Eliminar carrito</a>
            </div>
            <div class="info">
                <p>El envio se calculará posteriormente </p>
                <a href="">Continuar comprando</a>
            </div>
        </div>
    </div>
    <!-- <div class="cont-items">
        <h1>También te puede interesar <span>(59)</span></h1>
        <div class="items">
            <div class="item">
                <div class="img">
                    <img src="../../../assets/img/pruebas/producto.jpg" alt="">
                </div>
                <div class="info">
                    <div class="colores">
                        <span *ngFor="let rowColor of rowProducto.colores.slice(0, 5)" [title]="rowColor.color" [ngStyle]="{'background-color': rowColor.codigo_hexadecimal}" [@fadeInOut]></span>
                        <span class="mas" *ngIf="rowProducto.colores.length > 5" [@fadeInOut]>15+</span>
                    </div>
                    <p>Cono cometa tex 27 mod.74093 c/5000 mts</p>
                    <p class="precio">Pieza $17.50 MXN</p>
                </div>
            </div>
            <div class="item">
                <div class="img">
                    <img src="../../../assets/img/pruebas/producto.jpg" alt="">
                </div>
                <div class="info">
                    <div class="colores">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="mas">15+</span>
                    </div>
                    <p>Cono cometa tex 27 mod.74093 c/5000 mts</p>
                    <p class="precio">Pieza $17.50 MXN</p>
                </div>
            </div>
            <div class="item">
                <div class="img">
                    <img src="../../../assets/img/pruebas/producto.jpg" alt="">
                </div>
                <div class="info">
                    <div class="colores">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="mas">15+</span>
                    </div>
                    <p>Cono cometa tex 27 mod.74093 c/5000 mts</p>
                    <p class="precio">Pieza $17.50 MXN</p>
                    <p class="precio descuento">Pieza $17.50 MXN</p>
                </div>
            </div>
            <div class="item">
                <div class="img">
                    <img src="../../../assets/img/pruebas/producto.jpg" alt="">
                </div>
                <div class="info">
                    <div class="colores">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span class="mas">15+</span>
                    </div>
                    <p>Cono cometa tex 27 mod.74093 c/5000 mts</p>
                    <p class="precio">Pieza $17.50 MXN</p>
                </div>
            </div>
        </div>
        <a href="" class="button">Ver más</a>
    </div> -->
</div>