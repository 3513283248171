import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/core/servicios/api.service';
import { CarritoComponent } from '../paginas/carrito/carrito.component';
import { Observable, Subscription } from 'rxjs';
import { CarritoService } from 'src/app/core/servicios/carrito.service';
import { SessionService } from 'src/app/core/servicios/session.service';
import { Router } from '@angular/router';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { ModalPoliticasComponent } from '../paginas/modales/modal-politicas/modal-politicas.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	shrinkHeader: boolean = false;
	categorias: any = [];
	itemsCarrito: number = 0;
	sessionUser = false;
	celular: boolean = false;
	buscar: string = '';
	pageHeight: number = 0;
	dataSite: any = {};

	activarBuscar: boolean = false;
	@ViewChild("myNameElem") myNameElem!: ElementRef;
	constructor(
		@Inject(DOCUMENT) document: Document,
		private servicios: ApiService,
		private carritoService: CarritoService,
		private sessionService: SessionService,
		private router: Router,
		private dialog: MatDialog,
	) {}
	ngOnDestroy(): void {
		this.carritoSubscription.unsubscribe();
		this.clienteSubscription.unsubscribe();
	}

	private carritoSubscription!: Subscription;
	private clienteSubscription!: Subscription;


	ngOnInit() {
		this.animateHeader();
		this.getCategorias();
		this.pageHeight = window.screen.height;
		this.carritoSubscription = this.carritoService.carritoObservable.subscribe(items => {
			if(items) {
				this.itemsCarrito = items.length;
			}
		});
		this.clienteSubscription = this.sessionService.sessionObservable.subscribe(items => {
			if(Object.entries(items).length === 0) {
				this.sessionUser = false;
			} else {
				this.sessionUser = true;
			}
		});
		this.dataSite = this.sessionService.datosSite;
		this.sessionService.datosSiteObs.subscribe(items => {
			this.dataSite = items;
		});
	}
	animateHeader() {
		window.onscroll = () => {
			if (window.pageYOffset != 0 && window.pageYOffset > 135 && this.pageHeight < document.body.offsetHeight) {
				this.shrinkHeader = true;
			} else {
				this.shrinkHeader = false;
			}
		}
	}

	getCategorias() {
		this.servicios.getCategorias()
			.subscribe(
				response => {
					this.categorias = response.return;
				},
				error => {
					console.log(error);
				}
			);
	}

	openModalPoliticas() {
		const dialogRef = this.dialog.open(
			ModalPoliticasComponent,
			{
				data: {
					info: this.dataSite.politicas,
				}
			}
		);
	}

	enviar() {
		console.log(this.buscar);
		this.activarBuscar = false;
		setTimeout(() => {
			this.router.navigate(['/categoria/busqueda-producto'], {queryParams: {'buscar': this.buscar}});
		}, 100);
	}

	buscarProducto() {
		this.activarBuscar = !this.activarBuscar;
		if(this.activarBuscar == false) {
			this.buscar = '';
		} else {
			setTimeout(() => {
				this.myNameElem.nativeElement.focus();
			}, 10);
		}
	}
}
