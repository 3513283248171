<div class="cont-historia">
    <div class="banner">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" id="banner">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                <img src="{{ slide.img }}" width="100%">
            </div>
        </ngx-slick-carousel>
    </div>
    <div class="historia">
        <div class="img">
            <img src="../../../../assets/img/pruebas/banner-3.jpg" alt="">
        </div>
        <div class="texto">
            <h1>79 años de historia</h1>
            <p>
                Somos una empresa orgullosamente mexicana fundada en 1944, con 7 décadas de historia y más de 377 colaboradores trabajando para usted. Nos especializamos en Telas, Mercería, Manualidades, Decoración, Retapizados, Ropa y Generales. Contamos con 6 tiendas ubicadas en los estados de Yucatán, Campeche y Quintana Roo, y presencia comercial en Chiapas, Tabasco, Veracruz, Puebla y la Ciudad de México.
            </p>
            <a href="" class="button">Conoce nuestra historia</a>
        </div>
    </div>
    <div class="banner-registro">
        <!-- <h1>Conviértete en un distribuidor</h1> -->
        <h1>Visítanos y conoce todos los <br> productos que vendemos en Assis</h1>
        <!-- <form action="">
            <label for="">
                Correo electrónico *
                <input type="text">
            </label>
            <label for="">
                Contraseña *
                <input type="text">
            </label>
            <button class="button" type="submit">Registrarme</button>
        </form> -->
    </div>
    <div class="sucursales">
        <div class="litsta-sucursales">
            <h1>Nuestras tiendas</h1>
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigVertical" (init)="afterChange($event)">
                <div ngxSlickItem *ngFor="let row of sucursales; let key = index" class="item button-sucursal" attr.data-key="{{key}}" (click)="seleccionSucursal(key)">
                    <div class="img">
                        <!-- <img [src]="row.imagen" src-fallback="{{ default }}"> -->
                    </div>
                    <div class="texto">
                        <h1>{{row.nombre_sucursal}}</h1>
                        <P><b>Abierto</b> - {{row.horarios}}</P>
                        <p>{{row.calle}} Número ext: {{row.numero_exterior}} Int: {{row.numero_interior}} {{row.cruzamiento}} {{row.colonia}} {{row.ciudad}} {{row.estado}} C.P.:{{row.codigo_postal}}</p>
                        <!-- <P>Calle: Calle 52 Número ext: 455 Int: B Colonia: Col. Centro Ciudad: Mérida Estado: Yucatán C.P.: 97000</P> -->
                        <div class="info">
                            <a *ngIf="row.telefonos" href="tel:{{row.telefonos}}">{{row.telefonos}}</a>
                            <a *ngIf="row.url_sucursal" [href]="row.url_sucursal" target="_blank">Ubicación</a>
                        </div>
                        <div class="info" *ngIf="row.whatsapp">
                            <a href="https://wa.me/{{row.whatsapp}}" target="_blank">{{row.whatsapp}}</a>
                        </div> 
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
        <div class="cont-informacion-sucursal">
            <div class="img">
                <!-- <img [src]="sucursal.imagen" alt=""> -->
            </div>
            <div class="promociones">
                <h1>Promociones</h1>
                <div class="lista-promociones">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigPromociones">
                        <div ngxSlickItem *ngFor="let slide of slides" class="item">
                            <div class="img">
                                <!-- <img src="../../../../assets/img/pruebas/promocion.png" alt=""> -->
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
    <div class="cont-contacto">
        <div class="contacto">
            <h1>Contáctanos</h1>
            <form [formGroup]="formContacto" (submit)="enviar()">
                <label for="">
                    <input type="text" placeholder="Nombre" formControlName="nombre" [(ngModel)]="respuesta.nombre">
                </label>
                <label for="">
                    <input type="text" placeholder="Teléfono" formControlName="telefono" [(ngModel)]="respuesta.telefono">
                </label>
                <label for="">
                    <input type="text" placeholder="Email" formControlName="correo" [(ngModel)]="respuesta.correo">
                </label>
                <label for="">
                    <textarea placeholder="Mensaje" formControlName="mensaje" [(ngModel)]="respuesta.mensaje"></textarea>
                </label>
                <re-captcha formControlName="recaptchaReactive" [(ngModel)]="respuesta.recaptchaReactive"></re-captcha>
                <button type="submit" [disabled]="formContacto.invalid" class="button">Enviar mensaje</button>
            </form>
        </div>
        <div class="mapa">
            <h1>Encuentra nuestras oficinas</h1>
            <a href="">
                <img src="../../../../assets/img/mapa.jpg" alt="">
            </a>
        </div>
    </div>
</div>