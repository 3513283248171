import { Component } from '@angular/core';
import { CarritoService } from './core/servicios/carrito.service';
import { SessionService } from './core/servicios/session.service';
import { ApiService } from './core/servicios/api.service';
import { Subscription } from 'rxjs';
import { OnDestroy, OnInit } from 'node_modules/@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'Assis';
	constructor(
		private carritoService: CarritoService,
		private sessionService: SessionService,
		private servicios: ApiService,
		public router: Router
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  gtag('config', environment.googleAnalyticsId, {
				page_path: event.urlAfterRedirects
			  });
			}
		});
	}

	ngOnInit() {
		this.carritoService.getDataSave();
		this.subs.push(this.carritoService.carritoObservable.subscribe(items => {
			this.carritoService.dataSave(items);
		}));

		this.sessionService.getDataSave();
		this.subs.push(this.sessionService.sessionObservable.subscribe(items => {
			this.sessionService.dataSave(items);
		}));

		this.sessionService.getDataInfoSave();
		this.subs.push(this.sessionService.sessionInfoObservable.subscribe(items => {
			this.sessionService.dataSaveInfo(items);
		}));
		this.subs.push(this.servicios.getDataSite()
		.subscribe(
			response => {
				this.sessionService.datosSiteObsData = response.return;
			},
			error => {
				console.log(error);
			}
		));
	}

	private subs: Subscription[] = [];

	ngOnDestroy(): void {
		this.subs.map(s => s.unsubscribe());
	}
}
